import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = []
    isOnDashboard: boolean = false

    constructor(private router: Router) {}

    ngOnInit() {
        this.subscriptions.push(
            this.router.events.subscribe(routerEvent => {
                if (routerEvent instanceof NavigationEnd) {
                    this.isOnDashboard = routerEvent.url.indexOf('dashboard') > -1
                }
            })
        )
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    hideInfo() {
        const path = window.location.pathname
        return path.indexOf('/error') > -1 || path.indexOf('/forbidden') > -1 || path.indexOf('/unauthorized') > -1
    }
}
