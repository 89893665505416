import { NgModule } from '@angular/core';
import { AuthModule, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { appConfig } from './core/services/app-loader.service';

export const httpLoaderFactory = () => {
  return new StsConfigHttpLoader(appConfig)
}

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}