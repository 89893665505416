import { Injectable } from '@angular/core'
@Injectable()
export class LocalStorageService {
    // a class to wrap the local storage with a timestamp so that it can be expired
    // timestamp removed for now
    public static read(key: string): any {
        
        const record = JSON.parse(localStorage.getItem(key) || '{}')
        if(record && Object.keys(record).length === 0 && Object.getPrototypeOf(record) === Object.prototype){
            return null
        }

        return record
    }

    public static write(key: string, jsonData: any): void {
        // var expiry = 5 * 60 * 1000; //5 minutes
        // var record = { value: JSON.stringify(jsonData), timestamp: new Date().getTime() + expiry };
        localStorage.setItem(key, JSON.stringify(jsonData))
    }

    public static delete(key: string): void {
        localStorage.removeItem(key)
    }

    // cleans browsers local storage
    public static clearAll() {
        localStorage.clear()
    }
}
