import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
    public message = `Oop's something has gone wrong!`
    public messageCode: string

    firstDigit?: number
    secondDigit?: number
    thirdDigit?: number

    constructor(private route: ActivatedRoute) {
        this.messageCode = this.route.snapshot.params['errorCode']
    }

    ngOnInit() {
        if (+this.messageCode === 404) {
            this.message = 'Resource not found'
            this.startAnimation(4, 0, 4)
        } else {
            this.startAnimation(5, 0, 0)
        }
    }

    protected startAnimation(firstDigit: number, secondDigit: number, thirdDigit: number) {
        let loop1: Timeout,
            loop2: Timeout,
            loop3: Timeout,
            time = 15,
            i = 0

        loop3 = setInterval(() => {
            if (i > 40) {
                clearInterval(loop3)
                this.firstDigit = firstDigit
            } else {
                this.firstDigit = Math.floor(Math.random() * 9) + 1
                i++
            }
        }, time)
        loop2 = setInterval(() => {
            if (i > 80) {
                clearInterval(loop2)
                this.secondDigit = secondDigit
            } else {
                this.secondDigit = Math.floor(Math.random() * 9) + 1
                i++
            }
        }, time)
        loop1 = setInterval(() => {
            if (i > 100) {
                clearInterval(loop1)
                this.thirdDigit = thirdDigit
            } else {
                this.thirdDigit = Math.floor(Math.random() * 9) + 1
                i++
            }
        }, time)
    }
}
