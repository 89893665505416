import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe, CurrencyPipe } from '@angular/common'

import { ColumnType } from 'app/shared/components/generic-table/column-type.enum'

@Pipe({
    name: 'cellFormat',
})
export class CellFormatPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

    transform(value: any, ...args: any[]) {
        if (args.length > 0 && args[0] != null) {
            if (value == null) return null
            const format = args[0]
            switch (format) {
                case ColumnType.Date:
                    return this.datePipe.transform(value, 'dd MMM yyyy')
                case ColumnType.Time:
                    return this.datePipe.transform(value, 'shortTime')
                case ColumnType.DateTime:
                    return this.datePipe.transform(value, 'EEE d MMM, h:mm a')
                case ColumnType.LabelValue:
                    return value.label
                case ColumnType.Currency:
                    return this.currencyPipe.transform(value)
                default:
                    return value
            }
        } else {
            return value
        }
    }
}
