import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { appConfig } from '../services/app-loader.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: OidcSecurityService

    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        let accessToken: string = ''

        if (appConfig.value != '') {
            if (this.authService === undefined) {
                this.authService = this.injector.get(OidcSecurityService)
            }

            this.authService.getAccessToken().subscribe(token => {
                accessToken = token
            })
        }

        const authHeader = accessToken ? `Bearer ${accessToken}` : ''
        const newHeaders = authHeader
            ? {
                  setHeaders: { Authorization: authHeader },
              }
            : {}

        // For requests that are retried, we always want a fresh copy, else the request will have headers added multiple times.
        // So Clone the request before adding the new header.
        // NOTE: the cache and pragma are required for IE that will otherwise cache all get 200 requests.
        const authReq = request.clone(newHeaders)

        // Pass on the cloned request instead of the original request.
        return next.handle(authReq)
    }
}
