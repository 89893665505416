import { Injectable } from '@angular/core'
import { of } from 'rxjs'
@Injectable()
export class AppConfigurationService {
    private appConfig: any
    
    constructor() {}

    getConfig() {
        return of(this.appConfig)
    }

    setConfig(localAppConfig: any) {
        this.appConfig = localAppConfig
    }

    getConfigObject(): Promise<any> {
        return this.appConfig
    }
}
