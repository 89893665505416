import { Component, OnInit } from '@angular/core'
import { EventTypes, LoginResponse, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client'
import { AppSpinnerService } from './core/services/app-spinner.service'
import { UserInfoService } from './core/services/user-info.service'
import { MessageService } from 'primeng/api'
import { filter, take } from 'rxjs'
import { mountRouter } from './core/services/app-loader.service'
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    providers: [MessageService],
})
export class AppComponent implements OnInit {
    userLoaded = false
    configLoaded = false
    checkedLogin = false
    publicRoute = false

    get mountRouter() {
        return mountRouter.value
    }

    constructor(
        public oidcSecurityService: OidcSecurityService,
        private appSpinnerService: AppSpinnerService,
        private userInfoService: UserInfoService,
        private eventService: PublicEventsService
    ) {}

    ngOnInit() {
        this.publicRoute = window.location.pathname.indexOf('users/confirmation') !== -1 ? true : false

        this.appSpinnerService.setSpinner(true)

        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
            this.configLoaded = true
            this.appSpinnerService.setSpinner(false)

            if (loginResponse.isAuthenticated && !this.publicRoute && loginResponse.userData) {
                this.checkedLogin = true
                this.loadUserInfo(loginResponse)
            } else {
                const cookie = this.userInfoService.getCookieData()
                const value = this.userInfoService.getCookie(cookie.name)

                if (value === 'yes' && !loginResponse.errorMessage) {
                    this.oidcSecurityService.authorize()
                }

                if (value === '' && !loginResponse.isAuthenticated) {
                    mountRouter.next(true)
                }
            }

            this.eventService
                .registerForEvents()
                .pipe(filter(notification => notification.type === EventTypes.CheckSessionReceived))
                .subscribe(event => {
                    if (event.value == 'changed') {
                        mountRouter.next(false)
                        this.userInfoService.userLogout()

                        setTimeout(() => {
                            window.location.reload()
                        }, 500)
                    }
                })

            this.eventService
                .registerForEvents()
                .pipe(
                    filter(notification => notification.type === EventTypes.CheckSessionReceived),
                    take(1)
                )
                .subscribe(event => {
                    if (event.value == 'unchanged') {
                        setTimeout(() => {
                            mountRouter.next(true)
                        }, 500)
                    }
                })

            setInterval(() => {
                this.oidcSecurityService.checkSessionChanged$.subscribe(hasChange => {
                    if (hasChange) {
                        this.oidcSecurityService.forceRefreshSession().subscribe(data => {
                            if (!data) {
                                mountRouter.next(false)
                                this.oidcSecurityService.authorize()
                            }
                        })
                    }
                })
            }, 5000)
        })
    }

    loadUserInfo(loginResponse) {
        this.appSpinnerService.setSpinner(true)
        this.userInfoService.loadUserInfo(loginResponse).subscribe({
            next: () => {
                this.appSpinnerService.setSpinner(false)
                this.userLoaded = true
            },
            error: error => {
                setTimeout(() => {
                    this.userInfoService.userLogout()
                }, 3000)
            },
        })
    }
}
