import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { UnauthorizedComponent } from 'app/unauthorized/unauthorized.component'
import { ForbiddenComponent } from './forbidden/forbidden.component'
import { ErrorComponent } from './error/error.component'
import { SaleyarOperatordRoleGuard } from './core/services/saleyard-operator-role-guard.service'

const appRoutes: Routes = [
    // { path: '', component: HomeComponent},
    { path: '', redirectTo: '/auctions/dashboard', pathMatch: 'full' },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'agencies', loadChildren: () => import('app/agencies/agencies.module').then(m => m.AgenciesModule), canActivate: [SaleyarOperatordRoleGuard] },
    { path: 'auctions', loadChildren: () => import('app/auctions/auctions.module').then(m => m.AuctionsModule), canActivate: [SaleyarOperatordRoleGuard] },
    { path: 'users', loadChildren: () => import('app/users/users.module').then(m => m.UsersModule) },
    { path: '**', redirectTo: 'error;errorCode=404' },
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
