import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { mergeMap, tap, take } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'

import { Globals } from 'app/core/services/globals.service'
import { UrlService } from 'app/core/services/url.service'
import { BaseApiService } from 'app/core/services/base-api.service'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { UserData } from '../models/user-data-model'

@Injectable()
export class UserInfoService extends BaseApiService {
    private userLoadedSubject = new BehaviorSubject<boolean>(false)
    userLoaded$ = this.userLoadedSubject.asObservable()

    private _userData: UserData | null
    get userData(): UserData {
        return JSON.parse(JSON.stringify(this._userData || null))
    }

    private _configId: string
    get configId(): string {
        return this._configId
    }

    private _userPermissions: string[] | null
    get userPermissions(): string[] {
        return JSON.parse(JSON.stringify(this._userPermissions || null))
    }

    private _agencyId: number | null
    get agencyId(): number {
        return JSON.parse(JSON.stringify(this._agencyId || null))
    }

    private _agencyRole: string | null
    get agencyRole(): string {
        return JSON.parse(JSON.stringify(this._agencyRole || null))
    }

    get isAgencyOrProviderAdmin(): boolean {
        return [Globals.agencyUserType.AgencyAdmin, Globals.agencyUserType.ProviderAdmin].indexOf(this.agencyRole) !== -1
    }

    get isAgencyAdmin(): boolean {
        return [Globals.agencyUserType.AgencyAdmin].indexOf(this.agencyRole) !== -1
    }

    get isMarketOperator(): boolean {
        return [Globals.agencyUserType.MarketOperator].indexOf(this.agencyRole) !== -1
    }

    get isSaleyardOperator(): boolean {
        return [Globals.agencyUserType.SaleyardOperator].indexOf(this.agencyRole) !== -1
    }

    get isProviderAdmin(): boolean {
        return [Globals.agencyUserType.ProviderAdmin].indexOf(this.agencyRole) !== -1
    }

    get isAgencyAgent(): boolean {
        return [Globals.agencyUserType.Agent].indexOf(this.agencyRole) !== -1
    }

    get isAuctionAdmin(): boolean {
        return [Globals.agencyUserType.AuctionAdmin].indexOf(this.agencyRole) !== -1
    }

    get isBuyer(): boolean {
        return [Globals.siteUserType.Buyer].indexOf(this.agencyRole) !== -1
    }

    get isSystemAdmin(): boolean {
        return [Globals.agencyUserType.SystemAdmin].indexOf(this.agencyRole) !== -1
    }

    private _isAssessor: boolean
    get isAssessor(): boolean {
        return this._isAssessor
    }

    get myRoleCode(): string {
        if (this.isMarketOperator) {
            return 'marketOperator'
        }
        if (this.isSystemAdmin) {
            return 'sysAdmin'
        }
        if (this.isAuctionAdmin) {
            return 'auctionAdmin'
        }
        if (this.isAgencyAdmin) {
            return 'agencyAdmin'
        }
        if (this.isAgencyAgent && this.isAssessor) {
            return 'agentAssessor'
        }
        if (this.isAgencyAgent) {
            return 'agent'
        }
        if (this.isBuyer && this.isAssessor) {
            return 'buyerAssessor'
        }

        return 'unknown'
    }

    private _isAuthorized: boolean
    get isAuthorized(): boolean {
        return this._isAuthorized
    }

    private _userAccreditations: string[]

    constructor(
        private securityService: OidcSecurityService,
        http: HttpClient,
        url: UrlService
    ) {
        super(http, url)
    }

    /**
     * Loads user info from the API after logging in.
     */
    loadUserInfo(loginResponse) {
        this.securityService.userData$
            .pipe(
                take(1),
                tap(userData => {
                    this._userData = loginResponse.userData
                    this._configId = loginResponse.configId
                }),
                mergeMap(userData => this.getUserProfile(loginResponse.userData.sub)),
                tap(
                    data => {
                        this._userPermissions = [] // Clear permissions
                        this._userPermissions.push(...data.permissions.map(p => p.key))
                        this._userAccreditations = []
                        this._userAccreditations.push(...data.accreditations.map(p => p.stockTypeKey))
                        this._agencyId = data.agencyId
                        this._agencyRole = data.role.key
                        this._isAuthorized = true
                        this.userLoadedSubject.next(true) // Notify subscribers that the user has been loaded
                        this._isAssessor = data.isAccessorCertified
                    },
                    error => {}
                )
            )
            .subscribe()

        const cookie = this.getCookieData()
        document.cookie = `${cookie.name}=yes;path=/;domain=${cookie.domain};`

        const cookieLogin = this.getCookieData('IsNewLogin')
        document.cookie = `${cookieLogin.name}=yes;path=/;domain=${cookie.domain};`

        return this.securityService.userData$
    }

    /**
     * Given a permission key, checks whether the current user has that permission
     * @param permissionKey
     */
    userHasPermission(permissionKeys: string[]): boolean {
        if (this.userPermissions != null) {
            for (let i = 0; i < permissionKeys.length; i++) {
                const currentKey = permissionKeys[i]
                if (this.userPermissions.indexOf(currentKey) === -1) {
                    return false
                }
            }
            return true
        } else {
            return false
        }
    }

    /**
     * Log into Identity server
     */
    userLogin() {
        this.securityService.authorize()
    }

    /**
     * Log out of the app and identity server
     */
    userLogout() {
        this.resetUserInfo()
        this.securityService.logoff().subscribe()

        const cookie = this.getCookieData()
        document.cookie = `${cookie.name}=;path=/;domain=${cookie.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }

    /**
     * Resets the data stored in this service
     */
    private resetUserInfo() {
        this._userPermissions = null
        this._agencyId = null
        this._agencyRole = null
        this._userData = null
        this._isAuthorized = false
    }

    userAuthCallback() {
        //  this.securityService.authorizedImplicitFlowCallback()
    }

    userHasAccreditation(stockTypeKey: string): boolean {
        const foundKey = this._userAccreditations.find(function (element) {
            return stockTypeKey === element
        })
        return foundKey ? true : false
    }

    userSame(subjectId: string): boolean {
        if (subjectId == null || subjectId === '') {
            return false
        }

        return this._configId.toLocaleLowerCase() === subjectId.toLocaleLowerCase() ? true : false
    }

    getCookie(name: string): string {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
        if (match) return match[2]
        return ''
    }

    getCookieData(name: string = 'isAuthenticated') {
        const cookie = {
            name: name,
            domain: this.getCookieDomain(),
        }

        if (location.host.includes('localhost')) {
            cookie.name += '_dev'
            cookie.domain = 'localhost'
        }

        if (location.host.includes('.qa.')) {
            cookie.name += '_qa'
        }

        if (location.host.includes('.uat.')) {
            cookie.name += '_uat'
        }

        return cookie
    }

    getCookieDomain() {
        let host = location.host.replace('wwww.', '')
        let domain = host.split('.')
        let out: any = []

        const dotArray: Array<string> = []
        dotArray.push('')

        if (domain.length > 3) {
            domain = domain.splice(1, domain.length)
            out = dotArray.concat(domain)
            return out.join('.')
        }

        if (!host.includes('localhost')) {
            out = dotArray.concat(domain)
            return out.join('.')
        }

        return domain.join('.')
    }
}
