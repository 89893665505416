import { Injectable } from '@angular/core'

// Service for any utilities that are required for the site
@Injectable()
export class UtilityService {
    constructor() {}

    //DATE METHODS
    //this method will return the CURRENT date and time and convert to UTC
    //e.g. 11:30am -> uct OR 2:4pm -> utc
    //NOTE: in the morning utc date is 1 day earlier than utc in the afternoon
    public getTodayUctDate(): string {
        var currentLocalDate = new Date(Date.now())
        var currentUtcDate = currentLocalDate.toISOString()
        return currentUtcDate
    }

    //SEE comments as for getTodayUctDate()
    public getTomorrowUctDate(): string {
        var tomorrow = new Date(Date.now())
        tomorrow.setDate(new Date(Date.now()).getDate() + 1)
        var tomorrowUtc = tomorrow.toISOString()
        return tomorrowUtc
    }

    public getTodayStartUctDateTime(): string {
        var currentLocalDate = new Date(Date.now())
        var currentLocalDateStart = new Date(currentLocalDate.setHours(0, 0, 0, 0))
        var currentUtcDateStart = currentLocalDateStart.toISOString()
        return currentUtcDateStart
    }

    public getTodayEndUctDateTime(): string {
        var currentLocalDate = new Date(Date.now())
        var currentLocalDateEnd = new Date(currentLocalDate.setHours(23, 59, 59, 999))
        var currentUtcDateEnd = currentLocalDateEnd.toISOString()
        return currentUtcDateEnd
    }

    public getTomorrowStartUctDateTime(): string {
        var currentLocalDate = new Date(Date.now())
        var currentLocalTomorrowDate = new Date(currentLocalDate.setDate(currentLocalDate.getDate() + 1))
        var currentLocalDateTomorrowStart = new Date(currentLocalTomorrowDate.setHours(0, 0, 0, 0))
        var tomorrowUtcStart = currentLocalDateTomorrowStart.toISOString()

        return tomorrowUtcStart
    }

    //this has unit tests
    public getDateFromUct(serverDate: string): Date {
        var lastChar = serverDate.substr(serverDate.length - 1)

        if (lastChar != 'Z') {
            serverDate = serverDate + 'Z'
        }

        //convert to local date from Utc
        var localDate = new Date(serverDate)
        return localDate
    }

    //this has unit tests
    public getTimeTextFromUct(serverDate: string): string {
        var convertedDate = this.getDateFromUct(serverDate)

        //get time component as 11:25am or 2:15pm
        var hours = convertedDate.getHours()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12 || 12

        var minutes = convertedDate.getMinutes()
        var strMinutes = minutes < 10 ? '0' + minutes : minutes

        return `${hours}:${strMinutes}${ampm}`
    }

    public getDateTextFromUctFormatted(serverDate: string): string {
        var convertedDate = this.getDateFromUct(serverDate)

        var day = ('0' + convertedDate.getDate()).slice(-2)
        var month = ('0' + (convertedDate.getMonth() + 1)).slice(-2)
        var year = convertedDate.getFullYear()

        return `${day}/${month}/${year}`
    }

    public getStartOfDay(originalDate: Date): Date {
        return new Date(originalDate.setHours(0, 0, 0, 0))
    }

    public getEndOfDay(originalDate: Date): Date {
        return new Date(originalDate.setHours(23, 59, 59, 999))
    }
    //END OF DATE METHODS

    //ARRAY Methods
    public removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        })
    }
    //ARRAY Methods - End
}
