import { NgModule, ErrorHandler } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { NavigationComponent } from 'app/core/navigation/navigation.component'
import { SharedModule } from 'app/shared/shared.module'
import { AppRoutingModule } from 'app/app-routing.module'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { InformationComponent } from './information/information.component'
import { MessageBoxComponent } from 'app/core/message-box/message-box.component'
import { MessageBoxService } from 'app/core/message-box/services/message-box.service'
import { InformationService } from 'app/core/information/services/information.service'
import { UserInfoService } from 'app/core/services/user-info.service'
import { UrlService } from 'app/core/services/url.service'
import { BaseApiService } from 'app/core/services/base-api.service'
import { ErrorInterceptor } from 'app/core/interceptors/error-interceptor'
import { BreadcrumbService } from 'app/core/services/breadcrumb.service'
import { HeaderService } from 'app/core/services/header.service'
import { LoggingService } from 'app/core/services/logging.service'
import { CanDeactivateGuard } from 'app/core/services/can-deactivate-guard.service'
import { FormErrorHandlerService } from 'app/core/services/form-error-handler.service'
import { LocalStorageService } from 'app/core/services/local-storage.service'
import { RouteInterceptor } from 'app/core/interceptors/route-interceptor'
import { RoutingGaurd } from 'app/core/services/routing-gaurd.service'
import { SaleyarOperatordRoleGuard } from 'app/core/services/saleyard-operator-role-guard.service'
import { Globals } from 'app/core/services/globals.service'
import { AppSpinnerComponent } from './app-spinner/app-spinner.component'
import { AppSpinnerService } from 'app/core/services/app-spinner.service'
import { AppConfigurationService } from './services/app-configuration.service'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { UtilityService } from './services/utility.service'
import { RaygunErrorHandler } from './monitoring/app.raygun.setup'
import { AuthInterceptor } from './interceptors/auth-interceptor'
@NgModule({
    declarations: [NavigationComponent, FooterComponent, HeaderComponent, InformationComponent, MessageBoxComponent, AppSpinnerComponent],
    imports: [SharedModule, AppRoutingModule, BreadcrumbModule],
    exports: [AppRoutingModule, NavigationComponent, FooterComponent, HeaderComponent, InformationComponent, MessageBoxComponent, AppSpinnerComponent],
    providers: [
        BaseApiService,
        MessageBoxService,
        InformationService,
        BreadcrumbService,
        HeaderService,
        LoggingService,
        UserInfoService,
        UrlService,
        UtilityService,
        CanDeactivateGuard,
        FormErrorHandlerService,
        RouteInterceptor,
        RoutingGaurd,
        SaleyarOperatordRoleGuard,
        Globals,
        LocalStorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: RaygunErrorHandler,
        },
        AppSpinnerService,
        AppConfigurationService,
    ],
})
export class CoreModule {}
