<table mat-table [dataSource]="tableDataSource" class="table table-hover" id="genericTable">
    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{ disCol }}">
        <ng-container *ngIf="getColumnOption(disCol) as col">
            <th
                (click)="sort(col)"
                mat-header-cell
                *matHeaderCellDef
                [ngClass]="col.headerCellClass"
                [ngStyle]="col.headerCellStyle"
                [style.cursor]="col.sortable ? 'pointer' : 'auto'"
            >
                {{ col.name }}
                <span
                    *ngIf="orderBy != null && col.field === orderBy.field"
                    class="glyphicon"
                    [ngClass]="{
                        'glyphicon-chevron-up': orderBy.order === SortOrder.Ascending,
                        'glyphicon-chevron-down': orderBy.order === SortOrder.Descending
                    }"
                ></span>
            </th>
            <td mat-cell *matCellDef="let d" [ngClass]="isNowrap(col.format) ? 'nowrap-cell ' + col.cellClass : col.cellClass" [ngStyle]="col.cellStyle" class="fixed-height">
                <ng-container *ngIf="col.action == null; else actionView">
                    {{ d[col.field] | cellFormat: col.format }}
                </ng-container>
                <ng-template #actionView>
                    <ng-container *ngIf="!col.askConfirmation; else withConfirmation">
                        <a (click)="col.action(d)" *ngIf="!d[col.hideField] && ((col.hideFunction != null && !col.hideFunction(d)) || col.hideFunction == null)" class="a-link">{{
                            d[col.field]
                        }}</a>
                    </ng-container>
                    <ng-template #withConfirmation>
                        <a
                            class="a-link"
                            mwlConfirmationPopover
                            *ngIf="!d[col.hideField] && ((col.hideFunction != null && !col.hideFunction(d)) || col.hideFunction == null)"
                            [popoverTitle]="col.confirmationTitle"
                            [popoverMessage]="col.confirmationMessage"
                            placement="left"
                            (cancel)="onCancel()"
                            (confirm)="col.action(d)"
                        >
                            {{ d[col.field] }}
                        </a>
                    </ng-template>
                </ng-template>
            </td>
        </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div [class.hidden]="!tableDataSource || (tableDataSource && tableDataSource.data.length === 0)">
    <mat-paginator
        [pageSizeOptions]="[25, 50]"
        [pageSize]="pageSize"
        [length]="totalRecords"
        showFirstLastButtons
        #pager
        (page)="pageChange($event)"
        *ngIf="showPagination"
    ></mat-paginator>
</div>
