import { ErrorHandler, Injector, Injectable } from '@angular/core'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import * as StackTrace from 'stacktrace-js'

import { LoggingService } from 'app/core/services/logging.service'

@Injectable()
export class BaseErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any) {
        const loggingService = this.injector.get(LoggingService)

        const location = this.injector.get(LocationStrategy)

        const message = error.message ? error.message : error.toString()

        const url = location instanceof PathLocationStrategy ? location.path() : ''

        // get the stack trace
        StackTrace.fromError(error).then(stackframes => {
            const stackString = stackframes
                .splice(0, 20)
                .map(function (sf) {
                    return sf.toString()
                })
                .join('\n')

            // log on the server
            loggingService.log(message, url, stackString)
        })
        throw error
    }
}
