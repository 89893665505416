import { BsModalRef } from 'ngx-bootstrap/modal'
import { Injectable } from '@angular/core'

import { AppConfigurationService } from './app-configuration.service'

@Injectable()
export class Globals {
    public static AuctionFinaliseActions = {
        Finalise: 0,
        Exit: 1,
    }

    // a class to store global constants and global variables so that they are centralized in one place.
    // ** USER  **/
    // these must be static to be used in the routes
    public static permissions = {
        ManageAgencies: 'AG001',
        MyAgency: 'AG002',
        ViewAgencies: 'AG007',

        ManagePublicUsers: 'AUS001',

        ManageLiveAuctions: 'AU001',
        EditAuction: 'AU002',
        ViewAuction: 'AU005',
        ManageAuctionRegistrations: 'AU006',

        Dashboard: 'DB001',

        Classifieds: 'GLOBAL001',

        EditLots: 'LO001',
        ViewLots: 'LO002',
        ViewDraftLotsFromDashboard: 'LO004',
        AddLots: 'LO005',
        ManageLotStatus: 'LO006',
        ViewPendingAuctionListings: 'LO007',
        ViewPendingAgencyAuctionListings: 'LO008',
        ViewLotLists: 'LO009',
        ManageLotAgents: 'LT001',
        ManageAllListings: 'LT002',
        ManagePendingListings: 'LT004',
        ManageInfoPendingListings: 'LT005',
        ManageRestrictedVendors: 'VR003',
        EditPendingListings: 'LO010',
        ManageAuctionLotOrder: 'LO011',
        ManageLotStatusAssessment: 'LO012',
        ManagePostAuctionSales: 'LO013',
        UploadLots: 'LO014',

        Reports: 'RP001',

        DownloadPublicUsers: 'AUS002',
    }

    // ** STYLING for spinner **/
    public static sectionLoadingStyle = {
        backdropBorderRadius: '2px',
        backdropBackgroundColour: 'rgba(119,166,248,0.4)',
        primaryColour: '#5265B1',
    }

    public static siteLoadingStyle = {
        backdropBorderRadius: '2px',
        backdropBackgroundColour: 'rgba(230,231,232,0.4)',
        primaryColour: '#36394C',
    }

    // * POPUP Global Variable - needed to be able to close this globally *//
    public static modalRef: BsModalRef

    // ** REGEX for Email Validation **
    // is this required??? - yes as there is a bug in the validation that is built into angular for emails : Janine 15/3/2018
    public static emailRegex = '^[_a-zA-Z0-9-%+-]+(\\.[_a-zA-Z0-9-%+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.(([0-9]{1,3})|([a-zA-Z]{2,3})|(aero|coop|info|museum|name))$'
    // Regex for numbers only
    public static numbersOnlyRegex = '^[0-9]*$'

    // Edit options for site functionality
    public static EditModeOptions = {
        Any: 0,
        Edit: 1,
        Add: 2,
    }

    // User status types for the agency detail screen
    public static agencyUserStatusType = {
        New: '0',
        Existing: '1',
        Unconfirmed: '2',
    }

    // User types for the agency users screen
    public static agencyUserType = {
        Agent: 'Agent',
        AgencyAdmin: 'AgencyAdmin',
        ProviderAdmin: 'ProviderAdmin',
        MarketOperator: 'MarketOp',
        SaleyardOperator: 'SaleyardOp',
        AuctionAdmin: 'AuctionAdmin',
        SystemAdmin: 'SysAdmin',
    }

    // user types for the public users screens
    public static siteUserType = {
        Buyer: 'Buyer',
        Assessor: 'Assessor',
    }

    public static siteUserTypeAction = {
        BuyerEdit: 'BuyerEdit',
        BuyerNew: 'BuyerNew',
        BuyerEditNew: 'BuyerEditNew',
    }

    public static siteUserStatusType = {
        PendingVerification: 0,
        InActive: 1,
        Active: 2,
    }

    public static siteUserStatusTypeText = {
        0: 'PendingVerification',
        1: 'InActive',
        2: 'Active',
    }

    // sale types
    public static saletypes = {
        0: 'Wanted to buy',
        1: 'For sale',
        2: 'General sale',
        3: 'Clearing sale',
        4: 'Genetics',
    }

    // sales listings status types - used for the routing for the sales listings lists
    public static listingStatusType = {
        Pending: '0', // NOTE: these don't link to database values and they don't have to
        Waiting: '1',
        PendingWaitingSingle: '2',
        ApprovedSingle: '3',
        Draft: '4',
        Approved: '5',
    }

    // **URLS - api end points **//
    public static urls = {
        userPermissions: '/userprofiles/subjectId',

        agencies: '/agencies',
        agenciesStatus: '/agencies/statuses',
        agenciesRegions: '/agencies/agencyId/regions',
        agenciesAgents: '/agencies/agencyId/agents',
        agenciesTitles: '/agencies/agencyId/titles',
        agenciesRoles: '/agencies/agencyId/userprofiles/roles',
        agenciesListings: '/agencies/agencyId/listings',
        pricingOffers: '/agencies/listings/options',
        agenciesSites: '/agencies/agencyId/sites',

        agencyUsers: '/agencies/agencyId/users',
        agencyUnconfirmedUsers: '/agencies/agencyId/users/unconfirmed',
        agencyUnconfirmedUsersCount: '/agencies/agencyId/users/unconfirmed/count',
        agencyUserStatus: '/agencies/users/statuses',
        agencyInviteAgent: '/agencies/agencyId/userprofiles/agent/invitations',
        agencyInviteAdmin: '/agencies/agencyId/userprofiles/agencyadmin/invitations',
        agencyInviteResend: (agencyId: number, invitationId: number) => {
            return `/agencies/${agencyId}/users/unconfirmed/${invitationId}/resend`
        },

        newUserProfile: '/userprofiles',
        newUserRegister: '/agencies/users/registration/',
        buyerUserInvite: '/userprofiles/buyer/invitations',
        buyerUsersUnconfirmed: '/userprofiles/buyers/unconfirmed',
        usersBuyers: '/users/buyers',
        userInviteResend: (invitationId: number) => {
            return `/userprofiles/unconfirmed/${invitationId}/resend`
        },
        siteUserDetail: (userSubjectId: string) => {
            return `/users/buyers/${userSubjectId}`
        },
        siteUserNewDetail: (userId: string) => {
            return `/userprofiles/buyers/unconfirmed/${userId}`
        },
        newUserCheckUsername: '/userprofiles/find-username',

        pricingUnits: '/agencies/agencyId/saletypes/saleTypeId/stocktypes/stockTypeKey/pricingoptions',
        stocktypes: '/agencies/agencyId/stocktypes',
        categories: '/agencies/agencyId/stocktypes/stockTypeKey/categories',
        saletypes: '/saletypes',
        systemLog: '/log',

        listingsUpdate: '/agencies/agencyId/listings/listingId',
        listingDownload: '/agencies/agencyId/listings/listingId/download',
        mediaUpload: '/agencies/agencyId/listings/listingId/media',
        mediaUpdate: '/agencies/agencyId/listings/listingId/media/mediaId/meta',
        mediaDelete: '/agencies/agencyId/listings/listingId/media/mediaId',
        mediaLinkCreate: '/agencies/agencyId/listings/listingId/media/link',
        mediaLinkDelete: '/agencies/agencyId/listings/listingId/media/link/linkId',
        mediaLinkUpdate: '/agencies/agencyId/listings/listingId/media/link/linkId',

        stockCategoryGroups: '/agencies/agencyId/stocktypes/stockTypeKey/categories/groups',

        findUsername: '/userprofiles/find-username',

        getSale: (agencyId: number, saleId: number) => {
            return `/agencies/${agencyId}/sales/upcoming/${saleId}`
        },
        postSale: (agencyId: number) => {
            return `/agencies/${agencyId}/sales/upcoming`
        },
        putSale: (agencyId: number, saleEventId: number) => {
            return `/agencies/${agencyId}/sales/upcoming/${saleEventId}`
        },
        saleMediaPost: (agencyId: number, eventId: number, fileName: string, isPrimary: boolean, mediaType: any) => {
            return `/agencies/${agencyId}/events/${eventId}/media?FileName=${fileName}&IsPrimary=${isPrimary}&MediaType=${mediaType}`
        },
        saleMediaDelete: (agencyId: number, eventId: number, mediaId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/media/${mediaId}`
        },
        saleMediaPut: (agencyId: number, eventId: number, mediaId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/media/${mediaId}/meta`
        },

        getAllSales: (agencyId: number, status: number) => {
            return `/agencies/${agencyId}/sales/upcoming?Status=${status}`
        },
        getSalesListingCount: (agencyId: number) => {
            return `/agencies/${agencyId}/sales/upcoming/listingCount`
        },
        getSalesListings: (agencyId: number) => {
            return `/agencies/${agencyId}/events/listings`
        },
        deleteSaleListing: (agencyId: number, listingId: number) => {
            return `/agencies/${agencyId}/listings/${listingId}`
        },

        saleMediaLinkPost: (agencyId: number, eventId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/media/link`
        },
        saleMediaLinkPut: (agencyId: number, eventId: number, mediaLinkId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/media/link/${mediaLinkId}`
        },
        saleMediaLinkDelete: (agencyId: number, eventId: number, mediaLinkId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/media/link/${mediaLinkId}`
        },

        saleActivityStatus: (agencyId: number, eventId: number) => {
            return `/agencies/${agencyId}/events/${eventId}/status`
        },
        listingActivityStatus: (agencyId: number, listingId: number) => {
            return `/agencies/${agencyId}/listings/${listingId}/status`
        },
        postListing: (agencyId: number, stockTypeKey: string, categoryId: number) => {
            return `/agencies/${agencyId}/stocktypes/${stockTypeKey}/categories/${categoryId}/listings`
        },

        agencyReportsGet: (agencyId: number) => {
            return `/agencies/${agencyId}/reports`
        },
        agencyReportSubscriptionsAgents: (agencyId: number) => {
            return `/agency/${agencyId}/reports/agents/subscriptions`
        },
        agencyReportSubscriptions: (agencyId: number) => {
            return `/agency/${agencyId}/reports/subscriptions`
        },
        agencyReportEmails: (agencyId: number) => {
            return `/agencies/${agencyId}/reports/email`
        },
        assessmentDataConfiguration: (categoryId: string) => {
            return `/assessmentdata/configuration/${categoryId}`
        },
        emailReportsWithAttachments: agencyId => {
            return `/agencies/${agencyId}/reports/email`
        },

        // Assessors
        assessorUsers: '/assessors/users/search',
        getAllAssessors: '/assessors',
        accreditationTypes: '/accreditations/types',
        accreditationLevels: '/accreditations/levels',
        addAccreditation: '/accreditations',
        editAccreditation: '/accreditations',
        checkAccreditationNumber: '/accreditations/accreditationNumber',
        emailAssessor: '/assessor/email/send',
        getAccreditation: (Id: number) => {
            return `/accreditations/${Id}`
        },
        getUserAccreditations: (UserProfileId: number) => {
            return `/accreditations/user/${UserProfileId}`
        },
    }

    // ** Define filters **//
    public static listingFilters = {
        owned: { name: 'My Listings' },
        all: { name: 'All' },
    }

    // ** LISTING FILTERS END **//

    public static Months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sept',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    }

    public configuration

    // Give a string of format such as this 2018-04-09T01:05:06.619
    // Assuming that string is in UTC will convert it to UTC DateTime Object
    public static utcStringToUtcDateTime(dateTimeString: string) {
        const appendCharacter = !dateTimeString.endsWith('Z') ? 'Z' : ''
        return new Date(`${dateTimeString}${appendCharacter}`)
    }

    constructor(appSettings: AppConfigurationService) {
        appSettings.getConfig().subscribe(config => (this.configuration = config))
    }

    // this method will be called when a url is supplied as part of HAL response(where VD is already included)
    public getApiUrl(url: string) {
        const pipe = url[0] === '/' ? '' : '/'
        return this.configuration.api_url + pipe + url
    }

    // this method will be called when the url needs to be generated in full(need to add VD)
    public getApiUrlFull(which: string) {
        var x = ''
        if (this.configuration) {
            x = this.configuration.api_url + this.configuration.api_virtual_directory + which
        }

        return x
    }
}
