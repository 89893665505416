export enum AuctionStatus {
    Draft,
    Pending,
    Published,
    InEdit,
    Finalised,
    Ready,
    InProgress,
    Finished,
    Removed,
    Cancelled,
    Paused,
    Closed,
}
