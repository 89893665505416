import { Component, OnInit, Input } from '@angular/core'

import { AbstractBaseComponent } from 'app/shared/components/abstract-base-component'
import { AppSpinnerService } from 'app/core/services/app-spinner.service'
import { Globals } from 'app/core/services/globals.service'

@Component({
    selector: 'app-spinner',
    template: `<ngx-loading [show]="showAppSpinner" [config]="loadingNavStyle"></ngx-loading>`,
})
export class AppSpinnerComponent extends AbstractBaseComponent implements OnInit {
    @Input() delay = 300

    private currentTimeout: any
    showAppSpinner = false
    loadingNavStyle = Globals.sectionLoadingStyle
    countOfCalls = 0

    constructor(private appSpinnerService: AppSpinnerService) {
        super()
    }

    ngOnInit() {
        this.subscriptions.push(this.appSpinnerService.spinner$.subscribe(show => this.setDelayedSpinner(show)))
    }

    override ngOnDestroy() {
        super.ngOnDestroy()
        this.cancelTimeout()
    }

    /**
     * Delays the showing of the timer
     * @param show
     */
    private setDelayedSpinner(show: boolean) {
        const value = show
        // To show spinner for multiple requests record number of times show spinner called.
        // So then when spinner hide called same amount of times hide spinner.

        if (value) {
            // Increase number of calls
            this.countOfCalls++
        } else {
            if (this.countOfCalls > 0) {
                // Decrease number of calls
                this.countOfCalls--
            }
        }

        if (!value) {
            // Check whether there are calls not finished
            if (this.countOfCalls > 0) {
                // Do not hide spinner yet
                return
            }

            this.cancelTimeout()
            this.showAppSpinner = false
            return
        }
        if (this.currentTimeout) {
            return
        }
        this.currentTimeout = setTimeout(() => {
            this.showAppSpinner = value
            this.cancelTimeout()
        }, this.delay)
    }

    /**
     * Cancel the current timeout
     */
    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout)
        this.currentTimeout = undefined
    }
}
