import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'

import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { NgSelectModule } from '@ng-select/ng-select'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxLoadingModule } from 'ngx-loading'
import { DropdownModule } from 'primeng/dropdown'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { MultiSelectModule } from 'primeng/multiselect'
import { CalendarModule } from 'primeng/calendar'
import { InputSwitchModule } from 'primeng/inputswitch'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { SpinnerModule } from 'primeng/spinner'
import { ToastModule } from 'primeng/toast'
import { InputMaskModule } from 'primeng/inputmask'
import { SelectButtonModule } from 'primeng/selectbutton'
import { ConfirmationService } from 'primeng/api'
import { CheckboxModule } from 'primeng/checkbox'

import { DropdownToggleDirective } from 'app/shared/directives/dropdown-toggle.directive'
import { DropdownDirective } from 'app/shared/directives/dropdown.directive'
import { SortPipe } from 'app/shared/pipes/sort.pipe'
import { FilterPipe } from 'app/shared/pipes/filter.pipe'
import { AbsoluteUrlPipe } from 'app/shared/pipes/absolute-url.pipe'
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'
import { HumanReadablePipe } from 'app/shared/pipes/human-readable.pipe'
import { CurrencyInputComponent } from 'app/shared/components/forms/currency-input/currency-input.component'
import { DateTimeInputComponent } from 'app/shared/components/forms/date-time-input/date-time-input.component'
import { DialogComponent } from 'app/shared/components/forms/dialog/dialog.component'
import { WarningComponent } from 'app/shared/components/forms/warning/warning.component'
import { NumberInputComponent } from 'app/shared/components/forms/number-input/number-input.component'
import { SelectInputComponent } from 'app/shared/components/forms/select-input/select-input.component'
import { TextAreaInputComponent } from 'app/shared/components/forms/text-area-input/text-area-input.component'
import { TextInputComponent } from 'app/shared/components/forms/text-input/text-input.component'
import { MyTabsetComponent } from 'app/shared/components/custom-tabset/my-tabset.component'
import { MyTabDirective } from 'app/shared/components/custom-tabset/my-tab.directive'
import { MyTabHeadingDirective } from 'app/shared/components/custom-tabset/my-tab-heading.directive'
import { PasswordInputComponent } from 'app/shared/password-input.component'
import { MultiSelectInputComponent } from 'app/shared/components/forms/muti-select-input/multi-select-input.component'
import { MediaPopupComponent } from '../classifieds/listings/media/mediapopup.component'
import { GenericTableComponent, CellFormatPipe } from 'app/shared/components/generic-table'
import { TabHeaderComponent } from 'app/shared/components/tab-header/tab-header.component'
import { DateEntryInputComponent } from 'app/shared/components/forms/date-entry-input/date-entry-input.component'
import { CriteriaFilterPipe } from 'app/shared/pipes/criteria-filter'
import { GroupedMultiSelectComponent } from 'app/shared/components/forms/grouped-multi-select-input/grouped-multi-select-input.component'
import { LimitToDirective } from './directives/input-limit-to.directive'
import { ProcessedImageDirective } from './directives/processed-image.directive'
import { AutocompleteInputComponent } from './components/forms/autocomplete-input/autocomplete-input.component'
import { MaterialModule } from './material.module'
import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { NgSelectTypeComponent } from './components/ng-select/ng-select.component'
import * as fromValidators from './components/forms/validators'

@NgModule({
    declarations: [
        DropdownDirective,
        DropdownToggleDirective,
        LimitToDirective,
        SortPipe,
        FilterPipe,
        AbsoluteUrlPipe,
        MyTabsetComponent,
        MyTabDirective,
        MyTabHeadingDirective,
        HumanReadablePipe,
        CurrencyInputComponent,
        DateTimeInputComponent,
        DialogComponent,
        WarningComponent,
        NumberInputComponent,
        SelectInputComponent,
        TextAreaInputComponent,
        TextInputComponent,
        PasswordInputComponent,
        MultiSelectInputComponent,
        MediaPopupComponent,
        GenericTableComponent,
        CellFormatPipe,
        TabHeaderComponent,
        DateEntryInputComponent,
        CriteriaFilterPipe,
        GroupedMultiSelectComponent,
        ProcessedImageDirective,
        AutocompleteInputComponent,
        NgSelectTypeComponent,
    ],
    imports: [
        AutoCompleteModule,
        DropdownModule,
        MultiSelectModule,
        NgxLoadingModule,
        CommonModule,
        ModalModule,
        ReactiveFormsModule,
        FormsModule,
        TypeaheadModule.forRoot(),
        NgSelectModule,
        TabsModule.forRoot(),
        CalendarModule,
        InputSwitchModule,
        ConfirmDialogModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'primary',
            cancelButtonType: 'primary',
            confirmText: 'Yes',
            cancelText: 'No',
            reverseButtonOrder: true,
        }),
        SpinnerModule,
        ToastModule,
        RouterModule,
        InputMaskModule,
        MaterialModule,
        FormlyModule.forRoot({
            types: [{ name: 'ng-select', component: NgSelectTypeComponent }],
            validators: [{ name: 'no-accounts', validation: fromValidators.noVerifiedAccounts }],
        }),
        FormlyMaterialModule,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DropdownDirective,
        DropdownToggleDirective,
        LimitToDirective,
        AutoCompleteModule,
        DropdownModule,
        MultiSelectModule,
        SortPipe,
        FilterPipe,
        AbsoluteUrlPipe,
        TypeaheadModule,
        NgSelectModule,
        MyTabsetComponent,
        MyTabDirective,
        MyTabHeadingDirective,
        TabsModule,
        CalendarModule,
        ConfirmDialogModule,
        ConfirmationPopoverModule,
        HumanReadablePipe,
        InputSwitchModule,
        SelectButtonModule,
        ModalModule,
        NgxLoadingModule,
        CurrencyInputComponent,
        DateTimeInputComponent,
        DialogComponent,
        WarningComponent,
        NumberInputComponent,
        SelectInputComponent,
        TextAreaInputComponent,
        TextInputComponent,
        PasswordInputComponent,
        MultiSelectInputComponent,
        ToastModule,
        GenericTableComponent,
        CellFormatPipe,
        TabHeaderComponent,
        InputMaskModule,
        DateEntryInputComponent,
        CriteriaFilterPipe,
        GroupedMultiSelectComponent,
        ProcessedImageDirective,
        CheckboxModule,
        AutocompleteInputComponent,
        MaterialModule,
        FormlyModule,
        FormlyMaterialModule,
    ],
    //todo we can remove this with angular 9x
})
export class SharedModule {
    // Need the forRoot method which is to be only used once on import
    // on the AppComponent, otherwise import normally.
    // This is so that when we do lazy loading, the provider in this module only gets injected on the root injector (one instance)
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [ConfirmationService],
        }
    }
}
