import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Globals } from 'app/core/services/globals.service'

@Injectable()
export class LoggingService {
    constructor(private http: HttpClient, private globals: Globals) {}

    log(message: string, url: string, stack: any) {
        const data = {
            loc: url,
            message: message,
            stack: stack,
        }
        const body = JSON.stringify(data)
        const head = new HttpHeaders({
            'Content-Type': 'application/json',
        })
         
        const apiUrl = this.globals.getApiUrlFull(Globals.urls.systemLog)
         //  console.log('## OMG api url is', apiUrl)
        return this.http.post(apiUrl, body, { headers: head }).subscribe()
    }
}
