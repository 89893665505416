<div class="info-header container-fluid">
    <div class="col-12 col-md-12 col-lg-6 col-lg-6">
        <p-breadcrumb [model]="breadcrumb"></p-breadcrumb>
        <h2 class="text-heading info-title">{{ title }}</h2>
        <h5 *ngIf="subtitle">
            {{ subtitle }}
        </h5>
    </div>
    <ng-template #dynamicContent></ng-template>
    <app-message-box></app-message-box>
</div>
