import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

/**
 * Service for controlling the global app spinner
 * Used by the app spinner component
 */
@Injectable()
export class AppSpinnerService {
    private _subject = new BehaviorSubject<boolean>(false)
    spinner$ = this._subject.asObservable()

    constructor() {}

    /**
     * Show/Hide the app spinner
     * @param show
     */
    setSpinner(show: boolean) {
        this._subject.next(show)
    }
}
