export const environment = {
    production: true,
    stsServer: 'https://localhost:5000',
    redirect_url: 'http://localhost:4200',
    post_logout_redirect_uri: 'http://localhost:4200',
    well_known_config_url: 'https://localhost:44386/wellknownconfiguration.json',
    api_url: 'http://localhost:44397/manage-api',
    api_virtual_directory: '/api',
    app_version: 'Production Build',
    client_id: 'AuctionX.Web.Ui.Client.Ng',
}
