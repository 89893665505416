import { Component, OnInit } from '@angular/core'
import { environment } from 'environments/environment'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
    appVersion: string
    copyrightYear: number
    constructor() {}

    ngOnInit() {
        this.copyrightYear = Date.now()
        this.appVersion = environment.app_version
    }
}
