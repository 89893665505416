<div class="spinner" class="bidr">
    <nav class="navbar navbar-inverse navbar-expand-md navbar-dark fixed-top">
        <a class="navbar-brand nav-logo"></a>
        <div class="navbar-header">
            <button
                type="button"
                class="navbar-toggler collapsed"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="toggleState()"
            >
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon"> </span>
            </button>
        </div>
        <div class="collapse navbar-collapse nav-menu" [class.show]="show" id="navbarNav">
            <ul class="nav navbar-nav ms-auto">
                <ng-container>
                    <li class="nav-item" *ngIf="userIsAuthorized && (appConfig$ | async) as appConfig">
                        <a *ngIf="appConfig.public_site_url" href="{{ appConfig.public_site_url }}" target="_self" style="padding: 15px">Buyers Site</a>
                    </li>

                    <li class="nav-item" *ngIf="userIsAuthorized && allowCMS && (appConfig$ | async) as appConfig">
                        <a *ngIf="appConfig.wordpress_admin_page_url" href="{{ appConfig.wordpress_admin_page_url }}" target="_blank" style="padding: 15px"> Web Management </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="userIsAuthorized && allowDashboard">
                        <a routerLink="/auctions/dashboard" (click)="toggleState()" style="padding: 15px">Auctions</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" *ngIf="userIsAuthorized && allowAllAgencies">
                        <a routerLink="/agencies" (click)="toggleState()" style="padding: 15px">Agencies</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" *ngIf="userIsAuthorized && allowMyAgency">
                        <a routerLink="/agencies/agencies/{{ allowMyAgencyId }}/detail" (click)="toggleState()" style="padding: 15px">My Agency</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="allowAssessmentDataPreview">
                        <a routerLink="/auctions/assessmentDataPreview" style="padding: 15px" (click)="toggleState()">Assessment Data</a>
                    </li>
                    <li class="nav-item dropdown" class="app-dd dropdown" *ngIf="userIsAuthorized && allowUsers">
                        <a style="cursor: pointer" class="app-dd-child dropdown-toggle user-account" style="padding: 15px" role="button">
                            Users
                            <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li routerLinkActive="active" class="dropdowm-menu">
                                <a class="dropdown-item" style="padding: 15px" routerLink="/users/buyers" (click)="toggleState()">Buyers</a>
                            </li>
                            <li routerLinkActive="active" class="dropdowm-menu">
                                <a class="dropdown-item" style="padding: 15px" routerLink="/users/assessors" (click)="toggleState()">Assessors</a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
                <li routerLinkActive="active" *ngIf="!userIsAuthorized && showLogin">
                    <a style="cursor: pointer; padding: 15px" (click)="onLogin()">Login</a>
                </li>
                <li class="dropdown-menu" class="app-dd dropdown" *ngIf="userIsAuthorized">
                    <a style="cursor: pointer" class="app-dd-child dropdown-toggle user-account" role="button" style="padding: 15px">
                        {{ userName }}
                        <span class="user-logo"></span>
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="dropdowm-menu">
                            <a class="dropdown-item" style="cursor: pointer; padding: 15px" (click)="onLogout()">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
    <div class="nav-divider"></div>
</div>
