import { FormGroup } from '@angular/forms'
import { Injectable } from '@angular/core'

@Injectable()
export class FormErrorHandlerService {
    showGenericErrorMessage = false

    setControlErrors(response: any, form: FormGroup): any {
        const messages = response.error.Errors

        if (messages === undefined) {
            return 'Something has gone wrong. Try again.'
        }
        messages.forEach(
            message => {
                const formKey = form.controls[message.Key.toLowerCase()]

                if (formKey === undefined) {
                    this.showGenericErrorMessage = true
                } else {
                    formKey.setErrors({
                        remote: message.Message,
                    })

                    formKey.markAsTouched()
                }
            } // message
        )

        // return generic error message
        if (this.showGenericErrorMessage) {
            return 'Something has gone wrong. Try again.'
        }
    }
}
