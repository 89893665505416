import { Injectable, OnDestroy } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { Subscription } from 'rxjs'

import { Globals } from 'app/core/services/globals.service'

@Injectable()
export class RouteInterceptor implements OnDestroy {
    subscription: Subscription = new Subscription()

    constructor(private router: Router) {}

    getRouteStart() {
        this.subscription.add(
            this.router.events.subscribe(evt => {
                if (evt instanceof NavigationStart && Globals.modalRef !== undefined) {
                    Globals.modalRef.hide()
                }
            })
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
