import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

import { AgentBuyerLink } from 'app/agencies/models/agency-agent.model'
import { AuctionMedia } from 'app/auctions/models/auction-media'
import { AuctionStatus } from 'app/auctions/models/auction-status.enum'
import { VendorType } from 'app/auctions/models/vendor-type.enum'
import { LotsListQueryParams } from 'app/auctions/pages/manage-lots/models/lots-list-query-params.model'
import { MediaType } from 'app/classifieds/listings/models/media.model'
import {
    AgencyAgentResponse,
    AuctionActivityStatusResponse,
    AuctionConfigurationResponse,
    AuctionStatusResponse,
    AuctionVendorsResponse,
    BuyerResponse,
    GetAuctionLotsResponse,
    GetAuctionResponse,
    GetAuctionStockCategoriesResponse,
    GetAuctionsResponse,
    GetLotConfigurationResponse,
    GetLotResponse,
    GetStockTypesResponseModel,
    InvitationResponse,
    LotStatusResponse,
    PostAuctionResponse,
    PutAuctionRequest,
    RegionResponse,
    UserProfileResponse,
    VendorDto,
} from 'app/core/models/api'
import { PutAuctionLotRequest } from 'app/core/models/api/put-auction-lot-request.model'
import { UrlService } from 'app/core/services/url.service'
import { AuctionListFilter, CreditStatusFilter, Page } from 'app/shared/pipes/criteria-filter'
import { AuctionActivityStatus } from '../../auctions/models/auction-activity-status.enum'
import { LotActivityStatus } from '../../auctions/models/lot-activity-status.enum'
import { LotsListItemFinaliseModel } from '../../auctions/models/lots-list-item-finalise.model'
import { BidderAccounts } from '../models/api/bidder-accounts-response.model'
import { LotsBulkResponse } from '../models/api/lots-bulk-response.model'

const cacheBuster$ = new Subject<void>()

@Injectable()
export class BaseApiService {
    constructor(
        private http: HttpClient,
        public url: UrlService
    ) {}

    protected getUserProfile(subjectId: string): Observable<UserProfileResponse> {
        const url = this.url.userProfiles(subjectId)
        return this.http.get<UserProfileResponse>(url)
    }

    protected getUserAccreditations(userId: string): object {
        const url = this.url.userAccreditations(userId)
        return this.http.get<object>(url)
    }

    protected getAuctionConfiguration(): Observable<AuctionConfigurationResponse> {
        const url = this.url.auctionConfiguration()
        return this.http.get<AuctionConfigurationResponse>(url)
    }

    protected getAgencyAgents(agencyId: number, includeNonListed?: boolean, includedInactiveUsers?: boolean): Observable<AgencyAgentResponse> {
        const url = this.url.agencyAgents(agencyId)
        let httpParams = new HttpParams()
        if (includeNonListed != null) {
            httpParams = httpParams.append('IncludeNonListedAgents', includeNonListed.toString())
        }
        if (includedInactiveUsers != null) {
            httpParams = httpParams.append('IncludeInactiveUsers', includedInactiveUsers.toString())
        }
        return this.http.get<AgencyAgentResponse>(url, {
            params: httpParams,
        })
    }

    protected getVendors(accountNumber?: string): Observable<AuctionVendorsResponse> {
        const url = this.url.vendors()
        let httpParams = new HttpParams()
        if (accountNumber != null) {
            httpParams = httpParams.append('AccountNumber', accountNumber)
        }
        return this.http.get<AuctionVendorsResponse>(url, {
            params: httpParams,
        })
    }

    protected getBuyers(auctionId: number, accountNumber?: string): Observable<BuyerResponse[]> {
        const url = this.url.getBuyers(auctionId)
        let httpParams = new HttpParams()
        if (accountNumber != null) {
            httpParams = httpParams.append('FilterAccountNumber', accountNumber)
        }
        return this.http.get<any>(url, {
            params: httpParams,
        })
    }

    protected getInvitations(auctionId: number, invitationId?: number): Observable<InvitationResponse[]> {
        const url = this.url.auctionInvitation(auctionId, invitationId)
        return this.http.get<InvitationResponse[]>(url, {})
    }

    createInvitation(auctionId: number, invitation: InvitationResponse) {
        const url = this.url.auctionInvitation(auctionId)
        return this.http.post(url, invitation, {
            headers: {
                'Ignore-Error': 'true',
            },
        })
    }

    updateInvitation(auctionId: number, invitation: InvitationResponse): Observable<InvitationResponse> {
        const url = this.url.auctionInvitation(auctionId)
        return this.http.put<InvitationResponse>(url, invitation)
    }

    deleteInvitation(auctionId: number, invitationId: number) {
        const url = this.url.auctionInvitation(auctionId, invitationId)
        return this.http.delete(url)
    }

    postVendor(requestBody: VendorDto): Observable<VendorDto> {
        const url = this.url.vendors()
        return this.http.post<VendorDto>(url, requestBody, {
            headers: {
                'Ignore-Error': 'true',
            },
        })
    }

    getVendor(id: number): Observable<any> {
        const url = this.url.vendor(id)
        return this.http.get<VendorDto>(url)
    }

    getAuctionVendors(id: number): Observable<any> {
        const url = this.url.auctionVendors(id)
        return this.http.get<VendorDto>(url)
    }

    validateVendorAccount(query): Observable<any> {
        let url = this.url.vendors()
        url += query
        return this.http.head<VendorDto>(url)
    }

    putVendor(id: number, data): Observable<any> {
        const url = this.url.vendor(id)
        return this.http.put<VendorDto>(url, data)
    }

    getAgencyBuyerImpersonators(id: number, subjectId: string): Observable<AgentBuyerLink[]> {
        const url = this.url.agencyBuyerImpersonators(id, subjectId)
        return this.http.get<any>(url).pipe(
            map(r => {
                return r.userAccounts.map(item => {
                    const result: AgentBuyerLink = {
                        id: item.id,
                        fullName: item.fullName,
                        accountName: item.accountName,
                        accountNumber: item.accountNumber,
                        tradingName: item.tradingName,
                    }
                    return result
                })
            })
        )
    }

    searchAgencyBuyers(id: number, query: string): Observable<AgentBuyerLink[]> {
        const url = this.url.agencyBuyers(id, query)
        return this.http.get<any>(url).pipe(
            map(r => {
                return r.map(item => {
                    const result: AgentBuyerLink = {
                        id: item.id,
                        fullName: item.fullName,
                        accountName: item.accountName,
                        accountNumber: item.accountNumber,
                        tradingName: item.tradingName,
                    }
                    return result
                })
            })
        )
    }

    putAgencyBuyerImpersonators(id: number, subjectId: string, buyers: any): Observable<AgentBuyerLink[]> {
        const url = this.url.agencyBuyerImpersonators(id, subjectId)
        return this.http.put<any>(url, buyers).pipe(
            map(r => {
                return r.userAccounts.map(item => {
                    const result: AgentBuyerLink = {
                        id: item.id,
                        fullName: item.fullName,
                        accountName: item.accountName,
                        accountNumber: item.accountNumber,
                        tradingName: item.tradingName,
                    }
                    return result
                })
            })
        )
    }

    protected getRegions(): Observable<RegionResponse> {
        const url = this.url.regions()
        return this.http.get<RegionResponse>(url)
    }

    protected getAuctions(auctionStatuses: AuctionStatus[] = [], page: Page, filters: AuctionListFilter): Observable<GetAuctionsResponse> {
        const url = this.url.auctionsManage()
        let httpParams = new HttpParams()

        auctionStatuses.map(auctionStatus => {
            httpParams = httpParams.append('AuctionStatus', AuctionStatus[auctionStatus])
        })

        httpParams = this.appendParams(httpParams, filters)
        httpParams = this.appendParams(httpParams, page)

        return this.http.get<GetAuctionsResponse>(url, {
            params: httpParams,
        })
    }

    protected getHistoricAuctions(page: Page, filters: AuctionListFilter): Observable<GetAuctionsResponse> {
        const url = `${this.url.auctionsManage()}/historic`
        let httpParams = new HttpParams()

        httpParams = this.appendParams(httpParams, filters)
        httpParams = this.appendParams(httpParams, page)

        return this.http.get<GetAuctionsResponse>(url, {
            params: httpParams,
        })
    }

    protected getAllAuctions(query): Observable<GetAuctionsResponse> {
        let url = this.url.auctions()
        url += query
        return this.http.get<GetAuctionsResponse>(url)
    }

    protected getAuctionAccounts(page: Page, filters: CreditStatusFilter): Observable<any> {
        const url = this.url.auctionAccounts()
        let httpParams = new HttpParams()

        httpParams = this.appendParams(httpParams, filters)
        httpParams = this.appendParams(httpParams, page)

        return this.http.get<GetAuctionsResponse>(url, {
            params: httpParams,
        })
    }

    protected getAuctionBidderAccounts(): Observable<any> {
        const url = this.url.auctionBidderAccounts()
        return this.http.get<any>(url)
    }

    protected getAuctionAgencyValues(): Observable<any> {
        const url = this.url.auctionAgencies()
        return this.http.get<any>(url)
    }

    protected updateAuctionAccountsList(auctionAccounts: any): Observable<any> {
        const url = this.url.auctionAccounts()
        return this.http.put<any>(url, auctionAccounts)
    }

    protected updateBidderStatusForAuctions(auctionAccounts: any): Observable<any> {
        const url = this.url.auctionBidderAccounts()
        return this.http.put<any>(url, auctionAccounts)
    }

    protected removeBidderAccountForAuction(id: number): Observable<any> {
        const url = this.url.auctionBidderAccounts()
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                AuctionBidderId: id,
            },
        }
        return this.http.delete(url, httpOptions)
    }

    protected postAuctions(vendorType: VendorType, saleTypeId: number, pricingUnit: number, auctionFormat: string, auctionFormatLabel: string): Observable<PostAuctionResponse> {
        const url = this.url.auctionsManage()
        let httpParams = new HttpParams()
        httpParams = httpParams.append('VendorTypes', VendorType[vendorType])
        httpParams = httpParams.append('SaleTypeId', saleTypeId.toString())
        httpParams = httpParams.append('PricingOptions', pricingUnit.toString())
        httpParams = httpParams.append('Format', auctionFormat)
        httpParams = httpParams.append('FormatLabel', auctionFormatLabel)
        return this.http.post<PostAuctionResponse>(url, null, {
            params: httpParams,
        })
    }

    protected putAuctions(auctionId: number, requestBody: PutAuctionRequest): Observable<GetAuctionResponse> {
        const url = `${this.url.auctionsManage()}/${auctionId}`
        return this.http.put<GetAuctionResponse>(url, requestBody)
    }

    protected getAuction(auctionId: number): Observable<GetAuctionResponse> {
        const url = `${this.url.auctionsManage()}/${auctionId}`
        return this.http.get<GetAuctionResponse>(url)
    }

    protected deleteAuction(auctionId: number) {
        const url = `${this.url.auctionsManage()}/${auctionId}`
        return this.http.delete(url)
    }

    protected publishAuction(auctionId: number): Observable<GetAuctionResponse> {
        const url = this.url.auctionsPublish(auctionId)
        return this.http.put<GetAuctionResponse>(url, null)
    }

    protected closeAuction(auctionId: number, requestBody) {
        const url = this.url.manageAuctionStatus(auctionId)
        return this.http.put<GetAuctionResponse>(url, requestBody)
    }

    protected postFile(auctionId: number, query: string, data) {
        const url = this.url.auctionMedia(auctionId)
        const urlWithParams = url + query
        return this.http.post(urlWithParams, data)
    }

    protected deleteFile(fileId: number) {
        const fullUrl = this.url.auctionMedia(fileId)
        return this.http.delete(fullUrl)
    }

    protected postUrl(auctionId: number, data) {
        const fullUrl = this.url.auctionMediaLink(auctionId)
        return this.http.post(fullUrl, data)
    }

    protected deleteUrl(urlId: number) {
        const fullUrl = this.url.specificAuctionMediaLink(urlId)
        return this.http.delete(fullUrl)
    }

    protected putUrl(urlId: number, data) {
        const fullUrl = this.url.specificAuctionMediaLink(urlId)
        return this.http.put(fullUrl, data)
    }

    protected getStockTypes(agencyId: number | null): Observable<GetStockTypesResponseModel> {
        const fullUrl = this.url.getStockTypes(agencyId!)
        return this.http.get<GetStockTypesResponseModel>(fullUrl)
    }

    protected getAuctionStockCategories(stockTypeKey: string): Observable<GetAuctionStockCategoriesResponse> {
        const fullUrl = this.url.getAuctionStockCategories(stockTypeKey)
        return this.http.get<GetAuctionStockCategoriesResponse>(fullUrl)
    }

    protected getLotConfiguration(auctionId: number, lotId: number): Observable<GetLotConfigurationResponse> {
        const fullUrl = `${this.url.lotsManage(auctionId)}/${lotId}/configuration`
        return this.http.get<GetLotConfigurationResponse>(fullUrl)
    }

    protected postLot(auctionId: number, stockTypeCategoryId: number): Observable<GetLotResponse> {
        const fullUrl = `${this.url.lotsManage(auctionId)}`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('StockTypeCategoryId', stockTypeCategoryId.toString())
        return this.http.post<GetLotResponse>(fullUrl, null, {
            params: httpParams,
        })
    }

    protected postLotsBulk(auctionId: number, fileStream: any): Observable<LotsBulkResponse> {
        const fullUrl = `${this.url.auctionImportLots(auctionId)}`
        return this.http.post<LotsBulkResponse>(fullUrl, fileStream)
    }

    protected getLot(auctionId: number, lotId: number): Observable<GetLotResponse> {
        const fullUrl = `${this.url.lotsManage(auctionId)}/${lotId}`
        return this.http.get<GetLotResponse>(fullUrl)
    }

    getFilteredBuyerAccounts(searchedAccountNumber: string): Observable<BidderAccounts> {
        const fullUrl = `${this.url.getAllBuyerAccounts(searchedAccountNumber)}`
        return this.http.get<BidderAccounts>(fullUrl)
    }

    protected postLotMedia(auctionId: number, lotId: number, query: string, data): Observable<any> {
        let fullUrl = this.url.lotsManageMedia(auctionId, lotId)
        fullUrl += query
        return this.http.post(fullUrl, data)
    }

    protected putLotMedia(mediaId: number, query): Observable<any> {
        let fullUrl = this.url.putLotsManageMedia(mediaId)
        fullUrl += query
        return this.http.put(fullUrl, null)
    }

    protected deleteLotMedia(mediaId: number): Observable<any> {
        const fullUrl = this.url.deleteLotsManageMedia(mediaId)
        return this.http.delete(fullUrl)
    }

    protected postLotMediaLink(lotId: number, data): Observable<any> {
        const fullUrl = this.url.postLotManageMediaLink(lotId)
        return this.http.post(fullUrl, data)
    }

    protected putLotMediaLink(mediaId: number, data): Observable<any> {
        const fullUrl = this.url.lotManageMediaLink(mediaId)
        return this.http.put(fullUrl, data)
    }

    protected deleteLotMediaLink(mediaId: number): Observable<any> {
        const fullUrl = this.url.lotManageMediaLink(mediaId)
        return this.http.delete(fullUrl)
    }

    setupUrl(media: AuctionMedia) {
        // Setup full urls
        if (media.mediaType === MediaType.Link) {
            return
        }

        // media.url = this.globals.getApiUrl(media.url);
        // media.thumbnailUrl = this.globals.getApiUrl(media.thumbnailUrl);
        media.url = media.url
        media.thumbnailUrl = media.thumbnailUrl
    }

    protected putAuctionLot(auctionId: number, lotId: number, requestBody: PutAuctionLotRequest) {
        const fullUrl = `${this.url.lotsManage(auctionId)}/${lotId}`
        return this.http.put<GetLotResponse>(fullUrl, requestBody)
    }

    protected submitAuctionLotShortForm(auctionId: number, lotId: number) {
        const fullUrl = `${this.url.lotsManage(auctionId)}/${lotId}/submit-short-form`
        return this.http.put<GetLotResponse>(fullUrl, null)
    }

    protected submitAuctionLot(auctionId: number, lotId: number, isSubmitToAgent?: boolean) {
        const fullUrl = `${this.url.lotsManage(auctionId)}/${lotId}/submit`
        if (isSubmitToAgent) {
            return this.http.put<GetLotResponse>(fullUrl, { isSubmitToAgent: 'true' })
        }
        return this.http.put<GetLotResponse>(fullUrl, null)
    }

    protected approveAuctionLot(auctionId: number, lotId: number) {
        const fullUrl = `${this.url.lotsStatusManage(auctionId, lotId)}/approve`
        return this.http.put<GetLotResponse>(fullUrl, null)
    }

    protected rejectAuctionLot(auctionId: number, lotId: number) {
        const fullUrl = `${this.url.lotsStatusManage(auctionId, lotId)}/reject`
        return this.http.put<GetLotResponse>(fullUrl, null)
    }

    protected getNumberOfAuctionLots(auctionId, activityStatus?: LotActivityStatus[]): Observable<{ count: number }> {
        const url = this.url.numberOfAuctionLots(auctionId)
        let httpParams: HttpParams | undefined
        if (activityStatus != null) {
            httpParams = new HttpParams()
            activityStatus.forEach(status => {
                httpParams = httpParams!.append('ActivityStatus', LotActivityStatus[status])
            })
        }
        return this.http.get<{ count: number }>(url, {
            params: httpParams,
        })
    }

    protected getNumberOfLots(query: string): Observable<{ count: number }> {
        let url = this.url.numberOfLots()
        url += query
        return this.http.get<{ count: number }>(url)
    }

    protected getSpecificLotCount(query: string): Observable<{ count: number }> {
        let url = this.url.numberOfLotsSpecific()
        url += query
        return this.http.get<{ count: number }>(url)
    }

    protected getAuctionLots(auctionId, query): Observable<GetAuctionLotsResponse> {
        let url = this.url.auctionLots(auctionId)
        url += query
        return this.http.get<GetAuctionLotsResponse>(url)
    }

    protected getAuctionLotsFinalize(auctionId) {
        const url = this.url.auctionLotsFinalise(auctionId)
        return this.http.get(url)
    }

    protected saveAuctionLotsFinalize(auctionId: number, requestBody: LotsListItemFinaliseModel[]) {
        const url = this.url.auctionLotsFinalise(auctionId)
        return this.http.put(url, requestBody)
    }

    protected postPassedInLotSale(auctionId: number, lotId, sale: any) {
        const url = this.url.auctionPostAuctionSale(auctionId, lotId)
        return this.http.post<any>(url, sale)
    }

    protected postAfterAuctionSalePrice(auctionId: number, lotId, price) {
        const url = this.url.auctionPostAuctionSalePrice(auctionId, lotId)

        let queryParams: HttpParams = new HttpParams()
        queryParams = queryParams.append('Amount', price)

        return this.http.post<any>(url, null, { params: queryParams })
    }

    protected getFilteredLots(query) {
        let url = this.url.getLots()
        url += query
        return this.http.get(url)
    }

    protected getAllCompletedLots(query: LotsListQueryParams) {
        let queryParams: HttpParams = new HttpParams()

        query.status.forEach(st => (queryParams = queryParams.append('Status', st.toString())))
        query.assessmentStatus.forEach(ast => (queryParams = queryParams.append('AssessmentStatus', ast.toString())))

        // add dates if there are any
        if (query.dateFrom != null && query.dateTo != null) {
            queryParams = queryParams.append('AuctionDateFrom', query.dateFrom)
            queryParams = queryParams.append('AuctionDateTo', query.dateTo)
        }

        queryParams = queryParams.append('IncludeRemovedStatus', query.includeRemovedStatus.toString())

        //to exclude all Parent Lots
        // @ts-ignore: excludeParentLots is possibly 'Undefined'.
        queryParams = queryParams.append('ExcludeParentLots', 'excludeParentLots' in query ? query.excludeParentLots.toString() : true.toString())

        const url = this.url.getAllCompletedLots()
        return this.http.get(url, { params: queryParams })
    }

    protected getAllActionLots(query) {
        let queryParams: HttpParams = new HttpParams()

        queryParams = queryParams.append('PrimaryAgentOnly', query.PrimaryAgentOnly)
        queryParams = queryParams.append('AssessorOnly', query.AssessorOnly)

        query.status.forEach(st => (queryParams = queryParams.append('Status', st)))
        query.assessmentStatus.forEach(ast => (queryParams = queryParams.append('AssessmentStatus', ast)))
        query.activityStatus.forEach(as => (queryParams = queryParams.append('ActivityStatus', as)))

        const url = this.url.getAllActionLots()
        return this.http.get(url, { params: queryParams })
    }

    protected getCountAllActionLots(query) {
        let queryParams: HttpParams = new HttpParams()

        queryParams = queryParams.append('PrimaryAgentOnly', query.PrimaryAgentOnly)
        queryParams = queryParams.append('AssessorOnly', query.AssessorOnly)

        query.status.forEach(st => (queryParams = queryParams.append('Status', st)))
        query.assessmentStatus.forEach(ast => (queryParams = queryParams.append('AssessmentStatus', ast)))
        query.activityStatus.forEach(as => (queryParams = queryParams.append('ActivityStatus', as)))

        const url = this.url.getCountAllActionLots()
        return this.http.get(url, { params: queryParams })
    }

    protected deleteLot(auctionId, id) {
        const url = this.url.deleteLot(auctionId, id)
        return this.http.delete(url)
    }

    protected getAgency(agencyId) {
        const url = this.url.agency(agencyId)
        return this.http.get(url)
    }

    protected getAsBlob(url: string) {
        return this.http.get(url, { responseType: 'blob' })
    }

    protected putChangeLotStatus(auctionId: number, lotId: number, change: { changeReason: string; activityStatus: LotActivityStatus }) {
        const url = this.url.manageLotStatus(auctionId, lotId)
        return this.http.put<LotStatusResponse>(url, change)
    }

    protected getLotStatus(auctionId: number, lotId: number) {
        const url = this.url.manageLotStatus(auctionId, lotId)
        return this.http.get<LotStatusResponse>(url)
    }

    protected putChangeLotOrder(body: any) {
        const url = this.url.changeLotOrder()
        return this.http.put<any>(url, body)
    }

    protected putChangeAuctionActivityStatus(
        auctionId: number,
        change: {
            activityStatus?: AuctionActivityStatus
            startsAt?: Date
            changeReason?: string
        }
    ) {
        const url = this.url.manageAuctionActivityStatus(auctionId)
        return this.http.put<AuctionActivityStatusResponse>(url, change)
    }

    protected getAuctionActivityStatus(auctionId: number) {
        const url = this.url.manageAuctionActivityStatus(auctionId)
        return this.http.get<AuctionActivityStatusResponse>(url)
    }

    protected putChangeAuctionStatus(
        auctionId: number,
        change: {
            status: AuctionStatus
            changeReason?: string
        }
    ) {
        const url = this.url.manageAuctionStatus(auctionId)
        return this.http.put<AuctionStatusResponse>(url, change)
    }

    protected getAuctionStatus(auctionId: number) {
        const url = this.url.manageAuctionStatus(auctionId)
        return this.http.get<AuctionStatusResponse>(url)
    }

    protected getActiveBuyers() {
        const url = this.url.buyers()
        return this.http.get(url)
    }

    protected getBuyerProfile(id: string) {
        const url = this.url.buyerProfile(id)
        return this.http.get(url)
    }

    protected getBuyersCsv(): Observable<Blob> {
        const url = this.url.buyersCsv()
        return this.http.get(url, {
            responseType: 'blob',
        })
    }

    protected getPendingAccountRegistrations(): Observable<{ count: number }> {
        const url = this.url.pendingAccountRegistrations()
        return this.http.get<{ count: number }>(url)
    }

    protected getAuctionResultsCsv(auctionId: number): Observable<Blob> {
        const url = this.url.auctionResultsCsv(auctionId)
        return this.http.get(url, {
            responseType: 'blob',
        })
    }

    protected checkListingExists(listingId: number) {
        const url = this.url.listingExists(listingId)
        return this.http.get(url)
    }

    bustCache() {
        cacheBuster$.next()
    }

    appendParams(httpParams: HttpParams, newParams: Page | AuctionListFilter) {
        for (const [key, value] of Object.entries(newParams)) {
            if ((typeof value === 'number' && value === -1) || (typeof value === 'string' && value === '')) {
                continue
            }

            httpParams = httpParams.append(key, value)
        }

        return httpParams
    }
}
