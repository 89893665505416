import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MessageBox } from 'app/core/message-box/models/message-box.model'
import { MessageBoxService } from 'app/core/message-box/services/message-box.service'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.css'],

    animations: [
        trigger('fadeOut', [
            state(
                'fade',
                style({
                    opacity: 0,
                })
            ),
            state(
                'fadeRepeat',
                style({
                    opacity: 0,
                })
            ),
            state(
                'persist',
                style({
                    opacity: 1,
                })
            ),

            transition('* => fade', [
                style({ opacity: 1 }),
                // duration, delay, easing
                animate('300ms 4000ms ease-in-out'),
            ]),

            transition('* => fadeRepeat', [
                style({ opacity: 1 }),
                // duration, delay, easing
                animate('300ms 4000ms ease-in-out'),
            ]),

            transition('* => persist', animate(100)),
        ]), // trigger
    ], // animations
}) // component
export class MessageBoxComponent implements OnInit, OnDestroy {
    subscription: Subscription
    public showBox: boolean
    success: boolean
    messages: string[]
    state: string

    constructor(private messageBoxService: MessageBoxService) {
        this.showBox = false
        this.state = 'persist'
    }

    ngOnInit() {
        this.subscription = this.messageBoxService.message.subscribe(
            (message: MessageBox) => {
                // if message is empty - dont' show the message box
                if (message.messages.length === 0) {
                    this.showBox = false
                    this.messages = []
                } else {
                    // IF a message needs to be delivered - show box and message
                    this.showBox = true
                    this.success = message.isSuccess
                    this.messages = message.messages

                    this.state = !message.dontPersist ? 'fade' : 'persist'

                    if (this.success || message.dontPersist === true) {
                        // state must change for the animation to be repeated
                        if (this.state === 'fade') {
                            this.state = 'fadeRepeat'
                        } else {
                            this.state = 'fade'
                        }
                    } else {
                        this.state = 'persist'
                    }
                }
            } // subscription
        )
    } // onInit

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
