import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type } from '@angular/core'

// Service for loading dynamic components onto the information component
@Injectable()
export class InformationService {
    rootViewContainer?: ViewContainerRef

    constructor(private factoryResolver: ComponentFactoryResolver) {}

    setRootViewContainerRef(viewContainerRef?: ViewContainerRef) {
        // if (!viewContainerRef) return
        this.rootViewContainer = viewContainerRef
    }

    // Adds a dynamic component to the information view
    addDynamicComponent<T>(componentType: Type<T>, componentFactoryResolver?: ComponentFactoryResolver): T | void {
        if (!this.rootViewContainer) return

        // Can take in a ComponentFactoryResolver, this can be used when dynamically adding components
        // that are declared from a lazy loaded module, since the root factoryResolver does not have the component declared in there
        const factoryResolver = componentFactoryResolver || this.factoryResolver
        const factory = factoryResolver.resolveComponentFactory(componentType)
        const component = factory.create(this.rootViewContainer.injector) // Get injector of rootViewContainer
        this.rootViewContainer.insert(component.hostView)

        return component.instance
    }

    removeDynamicComponent() {
        if (this.rootViewContainer != null) {
            this.rootViewContainer.remove()
        }
    }
}
