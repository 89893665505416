export enum LotActivityStatus {
    InActive = 1,
    Active,
    Withdrawn,
    Removed,
    Sold,
    PassedIn,
    NotSold,
    Started,
    NowSelling,
    Paused,
    ResumeDelayed,
    FinishDelayed,
    StartDelayPaused,
    WithdrawnNotSold,
    SoldPostAuction,
}
