import { Injectable } from '@angular/core'
import { AppConfigurationService } from 'app/core/services/app-configuration.service'
import { BehaviorSubject } from 'rxjs'
import { UrlService } from './url.service'
import { baseConfig } from 'src/main'
import { LogLevel } from 'angular-auth-oidc-client'

export const mountRouter = new BehaviorSubject<boolean>(false)
export const appConfig = new BehaviorSubject<any>('')
export const userData = new BehaviorSubject<any>('')

@Injectable({ providedIn: 'root' })
export class StartupClass {
    constructor(private appConfigService: AppConfigurationService, private urlService: UrlService) {}

    load() {
        this.appConfigService.setConfig(baseConfig.value)
        this.urlService.setApiURL(baseConfig.value.api_url, baseConfig.value.api_virtual_directory)

        const finalConfig = {
            authority: baseConfig.value.stsServer,
            authWellknownEndpointUrl: baseConfig.value.auth_wellknown_endpoint_url,
            authWellknownEndpoints: baseConfig.value.auth_wellknown_endpoints,
            redirectUrl: baseConfig.value.redirect_url,
            clientId: baseConfig.value.client_id,
            responseType: baseConfig.value.response_type,
            scope: baseConfig.value.scope,
            postLogoutRedirectUri: baseConfig.value.post_logout_redirect_uri,
            silentRenew: baseConfig.value.silent_renew,
            silentRenewUrl: baseConfig.value.redirect_url + '/silent_renew.html',
            logLevel: LogLevel.None,
            startCheckSession: baseConfig.value.start_check_session,
            triggerAuthorizationResultEvent: baseConfig.value.trigger_authorization_result_event,
            maxIdTokenIatOffsetAllowedInSeconds: baseConfig.value.max_id_token_iat_offset_allowed_in_seconds,
            tokenRefreshInSeconds: baseConfig.value.token_refresh_in_seconds,
        }

        appConfig.next(finalConfig)

        return new Promise(resolve => setTimeout(resolve, 0))
    }
}
