import { Component, OnInit } from '@angular/core'
import { ErrorComponent } from 'app/error/error.component'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-unauthorized',
    templateUrl: '../error/error.component.html',
    styleUrls: ['../error/error.component.css'],
})
export class UnauthorizedComponent extends ErrorComponent implements OnInit {
    constructor(route: ActivatedRoute) {
        super(route)
    }

    override ngOnInit() {
        this.message = 'You are not authorised to access this page'
        this.startAnimation(4, 0, 1)
    }
}
