import * as rg4js from 'raygun4js'
import { BaseErrorHandler } from '../services/app-error-handler'
import { Injectable } from '@angular/core'

@Injectable()
export class RaygunErrorHandler extends BaseErrorHandler {
    override handleError(e: any) {
        rg4js('send', {
            error: e,
        })

        super.handleError(e)
    }
}
