import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CurrencyPipe, DatePipe } from '@angular/common'

import { ColumnOptions, ColumnOption } from 'app/shared/components/generic-table/column-options.model'
import { TableSortOrder } from 'app/shared/components/generic-table/table-sort-order.model'
import { SortOrder } from 'app/shared/components/generic-table/sort-order'
import { ColumnType } from 'app/shared/components/generic-table/column-type.enum'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'

@Component({
    selector: 'app-generic-table',
    templateUrl: './generic-table.component.html',
    styleUrls: ['./generic-table.component.css'],
    providers: [DatePipe, CurrencyPipe],
})
export class GenericTableComponent {
    private _data: any[]
    private _orderBy: TableSortOrder

    public SortOrder: any = SortOrder
    public tableDataSource: MatTableDataSource<any>

    @Input() totalRecords = 0
    @Input() showPagination = false
    @Input() columns: ColumnOptions
    @Input() dynamicSort = false
    @Input() get data(): any[] {
        return this._data
    }

    @Input() set orderBy(orderBy: TableSortOrder) {
        this._orderBy = orderBy
        this.orderData()
    }
    @Input() pageSize = 25

    @Output() onPageChange = new EventEmitter()
    @Output() onSortChange = new EventEmitter()

    get displayedColumns() {
        return this.columns.map(c => c.name)
    }

    set data(d: any[]) {
        this._data = d
        this.orderData()
    }

    get orderBy(): TableSortOrder {
        return this._orderBy
    }

    @ViewChild('pager') paginator: MatPaginator

    private orderData() {
        if (!(this.data.length <= 0 || this._orderBy == null) && !this.dynamicSort) {
            const columnFormat = this.columns.find(c => c.field === this.orderBy.field)!.format!
            this.data.sort((a, b) => {
                return this.orderFunction(this.orderBy.order, this.orderBy.field, columnFormat, a, b)
            })
        }

        this.tableDataSource = new MatTableDataSource<any>(this.data)
    }

    private orderFunction(sortOrder: SortOrder, field: string, format: ColumnType, a: any, b: any) {
        // Get the values first
        let vala = a[field]
        let valb = b[field]
        if (format === ColumnType.LabelValue) {
            vala = vala != null ? vala.label : null
            valb = valb != null ? valb.label : null
        }
        // Check if comparing numbers or strings
        if (!isNaN(vala) && !isNaN(valb)) {
            // are numbers
            return sortOrder === SortOrder.Ascending ? vala - valb : valb - vala
        } else {
            vala = vala || ''
            valb = valb || '' // ensure null values are empty converted to empty strings
            return ('' + vala).localeCompare(valb) * (sortOrder === SortOrder.Ascending ? 1 : -1)
        }
    }

    sort(column: ColumnOption) {
        if (!column.sortable) return

        if (this.orderBy != null) {
            const isNewField = this.orderBy.field !== column.field
            this.orderBy = {
                field: column.field!,
                order: isNewField ? SortOrder.Ascending : this.orderBy.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
            }
        } else {
            this.orderBy = {
                field: column.field!,
                order: SortOrder.Ascending,
            }
        }

        if (this.dynamicSort) {
            this.onSortChange.emit(this.orderBy)
        }
    }

    onCancel() {}

    isNowrap(format: ColumnType) {
        return format == ColumnType.Date || format == ColumnType.Time
    }

    getColumnOption(name: string) {
        return this.columns.find(d => d.name === name)
    }

    pageChange(page: PageEvent): void {
        this.pageSize = page.pageSize
        this.tableDataSource.data = []
        this.onPageChange.emit(page)

        const table = document.getElementById('genericTable')
        table?.scrollIntoView(true)
        setTimeout(() => {
            window.scrollBy(0, -50)
        }, 50)
    }
}
