<ng-select
    [ngClass]="{
        'ng-select-required': to.required
    }"
    [items]="getOptions"
    [bindLabel]="labelProp"
    [bindValue]="valueProp"
    [multiple]="to.multiple"
    [addTag]="to.addTag"
    [loading]="to.loading"
    [clearable]="to.clearable"
    (clear)="onClear()"
    [typeahead]="to.typeahead"
    [placeholder]="to.label"
    [formControl]="formControl"
    (search)="onSearch($event)"
    (add)="onAdd($event)"
    (remove)="onRemove($event)"
    (change)="onChange($event)"
></ng-select>
