import { Directive, HostBinding } from '@angular/core'

@Directive({
    selector: '.app-dd.dropdown',
})
export class DropdownDirective {
    @HostBinding('class.open') private isOpen = false

    constructr() {}

    toggleOpen() {
        this.isOpen = !this.isOpen
    }

    close() {
        this.isOpen = false
    }
}
