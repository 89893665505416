import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { ModalModule } from 'ngx-bootstrap/modal'

// modules
import { SharedModule } from 'app/shared/shared.module'
import { AppRoutingModule } from 'app/app-routing.module'
import { CoreModule } from 'app/core/core.module'

// components
import { AppComponent } from './app.component'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'
import { ForbiddenComponent } from './forbidden/forbidden.component'
import { ErrorComponent } from './error/error.component'
import { HttpClientModule } from '@angular/common/http'
import { AuthConfigModule } from './auth-config.module'
import { AbstractSecurityStorage } from 'angular-auth-oidc-client'
import { AuthLocalStorageService } from './core/services/auth-local-storage.service'
import { StartupClass } from './core/services/app-loader.service'
import { AppConfigurationService } from './core/services/app-configuration.service'
import { UrlService } from './core/services/url.service'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'

// services
@NgModule({
    declarations: [AppComponent, UnauthorizedComponent, ForbiddenComponent, ErrorComponent],
    imports: [BrowserModule, BrowserAnimationsModule, AuthConfigModule, ModalModule.forRoot(), AppRoutingModule, SharedModule.forRoot(), CoreModule, HttpClientModule],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [StartupClass, UrlService, AppConfigurationService, Sentry.TraceService],
            useFactory: (startupClass: StartupClass) => () => startupClass.load(),
        },
        { provide: AbstractSecurityStorage, useClass: AuthLocalStorageService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
