import { ElementRef, Directive, HostListener } from '@angular/core'

import { DropdownDirective } from 'app/shared/directives/dropdown.directive'

@Directive({
    selector: '.app-dd-child.dropdown-toggle',
})
export class DropdownToggleDirective {
    constructor(private dropdown: DropdownDirective, private elementRef: ElementRef) {}

    @HostListener('click', ['$event']) onClick($event: any) {
        $event.preventDefault()
        this.dropdown.toggleOpen()
    }

    @HostListener('document:click', ['$event.target']) onClickElsewhere(targetElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement)
        if (!clickedInside) {
            this.dropdown.close()
        }
    }
}
