import { OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { Injectable } from '@angular/core'

// TODO: Add Angular decorator.
@Injectable()
// TODO: Add Angular decorator.
export abstract class AbstractBaseComponent implements OnDestroy {
    protected subscriptions: Subscription[] = []

    ngOnDestroy() {
        // const subCount = this.subscriptions.length;
        this.subscriptions.forEach(s => s.unsubscribe())
        // console.log(this, `Unsubscribed ${subCount} subscription(s)`);
    }
}
