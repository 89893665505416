import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription, Observable } from 'rxjs'

import { Globals } from 'app/core/services/globals.service'
import { UserInfoService } from 'app/core/services/user-info.service'
import { AppConfigurationService } from '../services/app-configuration.service'

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    userName: string
    userIsAuthorized: boolean
    showLogin: boolean
    allowDashboard: boolean
    allowAllAgencies: boolean
    allowMyAgency: boolean
    allowUsers: boolean
    allowMyAgencyId: number
    allowCMS: boolean
    allowAssessmentDataPreview: boolean
    appConfig$: Observable<any>

    subscriptions: Subscription[] = []

    show = false

    constructor(
        private userInfoService: UserInfoService,
        private appConfigService: AppConfigurationService
    ) {
        this.showLogin = false
    }

    ngOnInit() {
        this.subscriptions.push(
            this.userInfoService.userLoaded$.subscribe(userLoaded => {
                if (userLoaded) {
                    this.load()
                } else {
                    this.allowDashboard = false
                    this.allowAllAgencies = false
                    this.allowMyAgency = false
                    this.userIsAuthorized = false
                    this.allowUsers = false
                    this.allowCMS = false
                    this.allowAssessmentDataPreview = false
                    this.showLogin = true
                }
            })
        )
        this.appConfig$ = this.appConfigService.getConfig()
    }

    toggleState() {
        this.show = !this.show
    }

    load() {
        if (this.userInfoService.userData != null) {
            this.userName = this.userInfoService.userData.name
            this.allowDashboard = this.userInfoService.userHasPermission([Globals.permissions.Dashboard])
            this.allowAllAgencies = this.userInfoService.userHasPermission([Globals.permissions.ManageAgencies])
            this.allowMyAgency = this.userInfoService.userHasPermission([Globals.permissions.MyAgency]) && !this.allowAllAgencies
            this.allowUsers = this.userInfoService.userHasPermission([Globals.permissions.ManagePublicUsers])
            this.allowCMS = this.userInfoService.isAuctionAdmin || this.userInfoService.isMarketOperator
            this.allowAssessmentDataPreview =
                this.userInfoService.isAuctionAdmin || this.userInfoService.isMarketOperator || this.userInfoService.isProviderAdmin || this.userInfoService.isSystemAdmin
            this.allowMyAgencyId = this.userInfoService.agencyId
        }
        this.userIsAuthorized = this.userInfoService.isAuthorized
    }

    onLogin() {
        this.userInfoService.userLogin()
    }

    onLogout() {
        this.userInfoService.userLogout()
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }
}
