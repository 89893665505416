<div class="error">
    <div class="container-floud">
        <div class="col-12 ground-color text-center">
            <div class="container-error-404">
                <div class="clip">
                    <div class="shadow">
                        <span class="digit thirdDigit">{{ firstDigit }}</span>
                    </div>
                </div>
                <div class="clip">
                    <div class="shadow">
                        <span class="digit secondDigit">{{ secondDigit }}</span>
                    </div>
                </div>
                <div class="clip">
                    <div class="shadow">
                        <span class="digit firstDigit">{{ thirdDigit }}</span>
                    </div>
                </div>
            </div>
            <h2 class="h1">{{ message }}</h2>
            <h4>
                <a routerLink="/auctions/dashboard">Click here to return to the dashboard</a>
            </h4>
        </div>
    </div>
</div>
