import { EventEmitter, Injectable } from '@angular/core'
import { MenuItem } from 'primeng/api/menuitem'

/**
 * A service provides functions to get and set the breadcrumb of the app
 */
@Injectable()
export class BreadcrumbService {
    items: MenuItem[] | null | undefined = []

    public breadcrumbChanged: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>()

    getBreadcrumb(): MenuItem[] | null | undefined {
        return this.items
    }

    setBreadcrumb(breadcrumb: MenuItem[] | null | undefined): void {
        this.items = breadcrumb
        this.breadcrumbChanged.next(this.items!)
    }
}
