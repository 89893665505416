import { throwError as observableThrowError, Observable } from 'rxjs'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
//cannot use the UserService due to cyclic reference
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { tap } from 'rxjs/operators'

import { Globals } from 'app/core/services/globals.service'
import { LocalStorageService } from 'app/core/services/local-storage.service'
import { UserInfoService } from '../services/user-info.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private securityService: OidcSecurityService

    constructor(private router: Router, private injector: Injector, private userInfoService: UserInfoService ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.securityService === undefined) {
            this.securityService = this.injector.get(OidcSecurityService)
        }

        if (req.headers.has('Ignore-Error')) {
            const originalHeaders = req.headers.delete('Ignore-Error')
            const cloneReq = req.clone({ headers: originalHeaders })
            return next.handle(cloneReq)
        }

        return <any>next.handle(req).pipe(
            tap(
                event => {
                    // this will have values for both the send request and the receive request
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 401:                                
                                LocalStorageService.delete('postLoginURL')
                                LocalStorageService.write('postLoginURL', this.router.url) // saves the url the user was trying to access
                                LocalStorageService.write('fromLogin', 'true')
                                this.userInfoService.userLogout()
                                break
                            case 403:
                                this.router.navigate(['./forbidden'])
                                break
                            case 404:
                                // we are not navigating to some other page for 404 as for some async validations,
                                // api returns a 404 if property to check is not found.
                                break
                            case 500:
                                // if this is due to an error in the popup, don't redirect, the popup will handle it.
                                if (Globals.modalRef === undefined) {
                                    this.router.navigate(['./error', { errorCode: 500 }])
                                }
                                break
                            default:
                                break
                        }
                    } else {
                        observableThrowError(error)
                    }
                }
            )
        )
    }
}
