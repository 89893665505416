import { Injectable } from '@angular/core'
@Injectable()
export class UrlService {
    private apiUrl

    constructor() { }

    setApiURL(apiURL:string, apiVirutlaDirectory: string){
        this.apiUrl = `${apiURL}${apiVirutlaDirectory}`
    }

    userProfiles(subjectId: string): string {        
        return `${this.apiUrl}/userprofiles/${subjectId}`
    }

    userAccreditations(userId: string): string {
        return `${this.apiUrl}/users/${userId}/accreditations`
    }

    agencyAgents(agencyId: number): string {
        return `${this.apiUrl}/agencies/${agencyId}/agents`
    }

    agency(agencyId: number): string {
        return `${this.apiUrl}/agencies/${agencyId}`
    }

    agencyBuyerImpersonators(agencyId: number, subjectId: string): string {
        return `${this.apiUrl}/agencies/${agencyId}/impersonators/${subjectId}/buyer-accounts`
    }

    agencyBuyers(agencyId: number, query: string): string {
        return `${this.apiUrl}/agencies/${agencyId}/buyers?FilterAccountNumber=${query}`
    }

    vendors(): string {
        return `${this.apiUrl}/auctions/vendors`
    }

    getBuyers(auctionId: number): string {
        return `${this.apiUrl}/auctions/${auctionId}/buyers`
    }

    getAllBuyerAccounts(searchedAccountNumber: string): string {
        return `${this.apiUrl}/auctions/buyers?AccountNumber=${searchedAccountNumber}`
    }

    auctionVendors(vendorId): string {
        return `${this.apiUrl}/auctions/${vendorId}/vendors`
    }

    vendor(vendorId: number): string {
        return `${this.apiUrl}/auctions/vendors/${vendorId}`
    }

    regions(): string {
        return `${this.apiUrl}/regions`
    }

    auctionConfiguration(): string {
        return `${this.apiUrl}/auctions/manage/configuration`
    }

    auctionsPublish(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/publish`
    }

    auctionsManage(): string {
        return `${this.apiUrl}/auctions/manage`
    }

    auctions(): string {
        return `${this.apiUrl}/auctions`
    }

    auctionAccounts(): string {
        return `${this.apiUrl}/agencies/users/credit-accounts`
    }

    auctionBidderAccounts(): string {
        return `${this.apiUrl}/bidders`
    }

    auctionAgencies(): string {
        return `${this.apiUrl}/agencies`
    }

    auctionMedia(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/media`
    }

    auctionMediaLink(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/media/links`
    }

    specificAuctionMediaLink(id: number): string {
        return `${this.apiUrl}/auctions/manage/media/links/${id}`
    }

    getFileLocation(fileUrl: string) {
        return `${this.apiUrl}/${fileUrl}`
    }

    getStockTypes(agencyId: number): string {
        return `${this.apiUrl}/agencies/${agencyId}/stocktypes`
    }

    getAuctionStockCategories(stockTypeKey: string): string {
        return `${this.apiUrl}/auctions/stocktypes/${stockTypeKey}/categories`
    }

    lotsManage(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/listings`
    }

    lotsManageMedia(auctionId: number, lotId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/listings/${lotId}/media`
    }

    putLotsManageMedia(id: number): string {
        return `${this.apiUrl}/auctions/manage/media/${id}/meta`
    }

    deleteLotsManageMedia(id: number): string {
        return `${this.apiUrl}/auctions/manage/media/${id}`
    }

    postLotManageMediaLink(lotId: number): string {
        return `${this.apiUrl}/auctions/manage/listings/${lotId}/links`
    }

    lotManageMediaLink(id: number): string {
        return `${this.apiUrl}/auctions/manage/listings/links/${id}`
    }

    numberOfAuctionLots(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/listings/count`
    }

    numberOfLots(): string {
        return `${this.apiUrl}/auctions/manage/listings/count`
    }

    numberOfLotsSpecific(): string {
        return `${this.apiUrl}/auctions/manage/listings/count/specific`
    }

    auctionLots(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/listings?AuctionId=${auctionId}`
    }

    auctionLotsFinalise(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/lots/finalise`
    }

    auctionPostAuctionSale(auctionId: number, lotId) {
        return `${this.apiUrl}/auctions/manage/${auctionId}/lots/${lotId}/PostAuctionSale`
    }
    auctionPostAuctionSalePrice(auctionId: number, lotId) {
        return `${this.apiUrl}/auctions/manage/${auctionId}/lots/${lotId}/PostAuctionSalePrice`
    }

    auctionSayleyardLot(auctionId: number): string {
        return `${this.apiUrl}/auctions/${auctionId}/saleyard/lots`
    }

    auctionImportLots(auctionId: number): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/listings/bulk`
    }

    getLots(): string {
        return `${this.apiUrl}/auctions/manage/listings`
    }

    getAllCompletedLots(): string {
        return `${this.apiUrl}/auctions/manage/listings/complete`
    }

    getAllActionLots(): string {
        return `${this.apiUrl}/auctions/manage/listings/action`
    }

    getCountAllActionLots(): string {
        return `${this.apiUrl}/auctions/manage/listings/action/count`
    }

    deleteLot(aucitonId, lotId): string {
        return `${this.apiUrl}/auctions/manage/${aucitonId}/listings/${lotId}`
    }

    manageLotStatus(auctionId: number, lotId: number): string {
        return this.lotsStatusManage(auctionId, lotId) + '/status'
    }

    changeLotOrder(): string {
        return `${this.apiUrl}/auctions/manage/auctionListingLotOrder`
    }

    lotsStatusManage(auctionId: number, lotId): string {
        return `${this.apiUrl}/auctions/manage/${auctionId}/lots/${lotId}`
    }

    manageAuctionActivityStatus(auctionId: number) {
        return `${this.apiUrl}/auctions/manage/${auctionId}/activity-status`
    }

    manageAuctionStatus(auctionId: number) {
        return `${this.apiUrl}/auctions/manage/${auctionId}/status`
    }

    buyers() {
        return `${this.apiUrl}/users/buyers?status=2`
    }

    buyerProfile(id: string) {
        return `${this.apiUrl}/users/buyers/${id}`
    }

    buyersCsv(): string {
        return `${this.apiUrl}/users/buyers/buyers.csv`
    }

    pendingAccountRegistrations(): string {
        return `${this.apiUrl}/agencies/users/credit-accounts/count`
    }

    auctionResultsCsv(auctionId: number): string {
        return `${this.apiUrl}/auctions/${auctionId}/results/auction-results.csv`
    }

    listingExists(listingId: number): string {
        return `${this.apiUrl}/listings/${listingId}/exists`
    }

    auctionInvitation(auctionId: number, invitationId?: number): string {
        const invitation = invitationId ? '/' + invitationId : ''
        return `${this.apiUrl}/auctions/${auctionId}/invitations${invitation}`
    }
}
