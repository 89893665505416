import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { Subscription, Subject, Observable } from 'rxjs'
import { NgSelectComponent, NgOption } from '@ng-select/ng-select'
import { debounceTime } from 'rxjs/operators'

@Component({
    selector: 'app-ng-select-type',
    templateUrl: './ng-select.component.html',
    styleUrls: ['./ng-select.component.scss'],
})
export class NgSelectTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit, OnDestroy {
    private subscriptions: Subscription[]
    private search: Subject<string>

    @ViewChild(NgSelectComponent, { static: true })
    control: NgSelectComponent

    // TODO come back to this
    get labelProp(): string {
        return this.to.bindLabel || 'label'
    }

    get valueProp(): string {
        return this.to.bindValue || 'value'
    }

    get getOptions() {
        let out = []
        if(typeof this.field.props.options != 'undefined'){
            const items: Observable<any> | any = this.field.props.options
            items.subscribe((data: any) => {
                out = data
            })
        }
        
        return out
    }

    ngAfterViewInit(): void {
        this.subscribeToValueChange()
        if (this.to.onSearch) {
            const interval = this.to.debounceTime ? this.to.debounceTime : 0
            this.search = new Subject()

            this.subscriptions.push(
                this.search.pipe(debounceTime(interval)).subscribe(filter => {
                    this.to.onSearch(filter)
                })
            )
        }

        if (this.to.deselectMode === 'change') {
            this.subscriptions.push(
                (this.to.options as Observable<any>).subscribe(() => {
                    this.control.selectedItems.forEach(item => {
                        this.control.unselect(item)
                    })
                })
            )
        } else if (this.to.deselectMode === 'missing' && this.to.options && !Array.isArray(this.to.options)) {
            this.subscriptions.push(
                (this.to.options as Observable<any>).subscribe((to: any[]) => {
                    const toRemove: NgOption[] = []

                    this.control.selectedItems.forEach(item => {
                        if (to.findIndex(cmp => cmp[this.valueProp] === item.value![this.valueProp]) < 0) {
                            toRemove.push(item)
                        }
                    })

                    toRemove.forEach(target => {
                        this.control.unselect(target)
                    })
                })
            )
        }
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach(s => s.unsubscribe)
        }
    }

    onSearch(filter: any) {
        if (this.search) {
            this.search.next(filter !== undefined ? filter.term : '')
        }
    }

    onClear() {
        if (this.to.onClear) {
            this.to.onClear()
        }
    }

    onAdd(item: any) {
        if (this.to.onAdd) {
            this.to.onAdd(item)
        }
    }

    onRemove(item: any) {
        if (this.to.onRemove) {
            this.to.onRemove(item.value)
        }
    }

    onChange(item: any) {
        if (this.to.onChange) {
            this.to.onChange(item)
        }
    }

    private subscribeToValueChange() {
        if (this.formControl.value) {
            this.subscriptions.push(this.formControl.valueChanges.subscribe((value: string) => (this.to.ngSelectChange ? this.to.ngSelectChange(value) : null)))
        }
    }
}
