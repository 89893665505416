import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { filter, take, map } from 'rxjs/operators'

import { UserInfoService } from 'app/core/services/user-info.service'

@Injectable()
export class RoutingGaurd implements CanActivate {
    constructor(private router: Router, private userInfoService: UserInfoService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const permissionsKeys = [].concat(route.data['permissionKey'] || [])
        return this.userInfoService.userLoaded$.pipe(
            filter(loaded => loaded),
            take(1),
            map(() => {
                const hasPermission = this.userInfoService.userHasPermission(permissionsKeys)
                if (!hasPermission) {
                    this.router.navigate(['/forbidden'])
                    return false
                } else {
                    return true
                }
            })
        )
    } // canActivate
}
