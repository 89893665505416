import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, Input } from '@angular/core'
import { Subscription } from 'rxjs'
import { MenuItem } from 'primeng/api/menuitem'

import { BreadcrumbService } from 'app/core/services/breadcrumb.service'
import { HeaderService } from 'app/core/services/header.service'
import { InformationService } from 'app/core/information/services/information.service'
import { Globals } from 'app/core/services/globals.service'
import { UserInfoService } from 'app/core/services/user-info.service'

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.css'],
})
export class InformationComponent implements OnInit, OnDestroy {
    @ViewChild('dynamicContent', {
        read: ViewContainerRef,
        static: true,
    })
    viewContainerRef: ViewContainerRef
    @Input() isOnDashboard: boolean

    canSwitchToClassifieds = false
    breadcrumb: MenuItem[]
    title: string
    subtitle: string
    subscriptions: Subscription[] = []
    constructor(
        private breadcrumbService: BreadcrumbService,
        private headerService: HeaderService,
        private informationService: InformationService,
        private userInfoService: UserInfoService
    ) {
        // whenever breadcrumb changes, update it
        this.subscriptions.push(
            this.breadcrumbService.breadcrumbChanged.subscribe(breadcrumb => {
                this.breadcrumb = breadcrumb
            })
        )
    }

    ngOnInit() {
        // get page header
        this.subscriptions.push(
            this.headerService.headerTitle.subscribe((title: string) => {
                this.title = title
            })
        )
        this.subscriptions.push(
            this.headerService.headerSubtitle.subscribe((subtitle: string) => {
                this.subtitle = subtitle
            })
        )
        this.informationService.setRootViewContainerRef(this.viewContainerRef)
        // this.informationService.addDynamicComponent();
        this.subscriptions.push(
            this.userInfoService.userLoaded$.subscribe(userLoaded => {
                if (userLoaded) {
                    this.canSwitchToClassifieds = this.userInfoService.userHasPermission([Globals.permissions.Classifieds])
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
        this.informationService.setRootViewContainerRef(undefined)
    }
}
