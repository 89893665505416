import { Component, OnInit } from '@angular/core'
import { ErrorComponent } from 'app/error/error.component'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-forbidden',
    templateUrl: '../error/error.component.html',
    styleUrls: ['../error/error.component.css'],
})
export class ForbiddenComponent extends ErrorComponent implements OnInit {
    constructor(router: ActivatedRoute) {
        super(router)
    }

    override ngOnInit() {
        this.message = 'Sorry, you are not allowed to access this page'
        this.startAnimation(4, 0, 3)
    }
}
